import { ClassEvents, IDictionary, SyncProvider } from "./Interfaces";
import { Dropbox as Dbx, DropboxAuth, DropboxResponseError, Error, files } from "dropbox";

import localforage from "localforage";
import { dataKeys } from "../Globals";
import Swal from "sweetalert2";
import { toProperCase, DeleteBackupProvider } from "./Preferences";

export interface Token {
	access_token: string;
	token_type: string;
	expires_in: number;
	refresh_token: string;
	scope: string;
	uid: string;
	account_id: string;
}

export interface DownloadResponse extends files.FileMetadata {
	fileBlob: Blob;
}

export default class Dropbox implements SyncProvider, ClassEvents {
	public backupDateTime: string = 'Not Set';
	public async completeSyncKey(): Promise<boolean> {
		let codeVerifer = await localforage.getItem<string>(dataKeys.CODEVERIFER)
		let backupAuthToken = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN)
		return codeVerifer && backupAuthToken ? true : false
	}

	listeners: IDictionary = {};

	constructor() {
		let self = this;
		localforage.getItem<string>("backupDateTime").then((backupDate: string | null) => { self.backupDateTime = backupDate === null ? 'Not Set' : backupDate })
	};

	public addEventListener(type: string, listener: Function): void {
		this.listeners[type] = listener;
	}

	public removeEventListener(type: string): void {
		delete this.listeners[type];
	}

	public dispatchEvent(type: string, payload: any): void {
		if (this.listeners[type]) {
			this.listeners[type](payload);
		}
	}

	name: string = "Dropbox";
	instructions: string = "Dropbox";
	subtext: string = "We are now going to authenticate the M.A. Rooney Foundation to upload files to your DropBox. Further instructions & details will be provided on the next screen. You will be temporarily directed to Dropbox and then returned to the homepage of this app.";

	public async auth(): Promise<void> {
		let redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
		let state = undefined;
		let usePKCE = true;

		let auth = new DropboxAuth({ clientId: "2ar4lg23id993vu" });

		let authUrl = await auth.getAuthenticationUrl(redirectUri, state, "code", "offline", undefined, "none", usePKCE);

		await localforage.setItem(dataKeys.CODEVERIFER, auth.getCodeVerifier());

		const win: Window = window;

		win.location = authUrl as string;
	}

	public async setup(): Promise<void> { }

	public async backup(): Promise<void> {
		let token = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN)

		//if (!token) { return };

		await this.RefreshAccessToken();

		let decks = await localforage.getItem(dataKeys.DECKS)

		//if (!decks) { return; }

		if (!navigator.onLine) {
			Swal.fire({
				title: "No Network",
				text: "Please check your network connection and try again.",
				icon: "warning"
			});
			return;
		}

		let dbx = new Dbx({ accessToken: token?.toString() });

		//let currentDate = moment(); RNH Replaced this varialbe 1 to 7 Backup
		let downloadText = JSON.stringify(decks, undefined, "\t");
		let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });
		let file = new File([blob], await this.fileName(), { type: "application/json;charset=utf-8" });
		let metadata;
		try {
			metadata = await dbx.filesUpload({
				path: `/${file.name}`,
				mode: { ".tag": "overwrite" },
				contents: file
			});
			this.dispatchEvent("backup", metadata)
		} catch (e) {
			console.log(1)
			await this.checkRequest(e as DropboxResponseError<object>)
			return;
		}

		let localTime = new Date(metadata.result.client_modified);
		await localforage.setItem(dataKeys.LASTFILEBACKUP, localTime.getTime());
	}

	public async sync(): Promise<void> {
		let token = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN)

		//if (!token) { return; }

		let dbx = new Dbx({ accessToken: token?.toString() });
		let response;

		try {
			response = await dbx.filesSearchV2({ query: "DeckBackup", options: { "order_by": { ".tag": "last_modified_time" } } });
		} catch (e) {
			console.log(2)
			await this.checkRequest(e as DropboxResponseError<object>)
			return;
		}
		if (!response.result.matches[0]) { return; }

		let metadata = (response.result.matches[0].metadata as any).metadata as files.FileMetadata;

		let dbxFilename = metadata.name;

		let downloadResponse = await dbx.filesDownload({ path: '/' + dbxFilename });

		let result = downloadResponse.result as DownloadResponse

		let data = await new Promise((resolve) => {
			var blob = result.fileBlob;
			var reader = new FileReader();
			reader.addEventListener("loadend", async function () {
				let data = JSON.parse(reader.result as string);
				await localforage.setItem(dataKeys.DBCLOUDDATA, data);
				resolve(data);
			});
			reader.readAsText(blob);
		});

		await localforage.setItem(dataKeys.DBCLOUDDATA, data);
	}

	public async synccheck(): Promise<boolean> {
		return true;
	}

	public async fileName(): Promise<string> {
		return this.getBackupFileName();
	}

	public async token(code: string): Promise<void> {
		let auth = new DropboxAuth({ clientId: "2ar4lg23id993vu" });
		var redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

		let codeVerifier: string | null = await localforage.getItem<string>("codeVerifier");

		if (codeVerifier === null) {
			return;
		}

		auth.setCodeVerifier(codeVerifier);

		let response;
		try {
			response = await auth.getAccessTokenFromCode(redirectUri, code);
		} catch (e) {
			console.log(3)
			await this.checkRequest(e as DropboxResponseError<object>)
			return;
		}

		let result = response.result as Token;
		await localforage.setItem(dataKeys.LASTREFRESHTIME, new Date().getTime());
		await localforage.setItem(dataKeys.REFRESHTIME, result.expires_in);
		await localforage.setItem(dataKeys.REFRESHTOKEN, result.refresh_token);
		await localforage.setItem(dataKeys.BACKUPAUTHTOKEN, result.access_token);
	}

	async getBackupFileName(): Promise<string> {
		let token = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN);

		if (!token) {
			return "DeckBackup1.json";
		}

		let dbx = new Dbx({ accessToken: token });
		let response;

		try {
			response = await dbx.filesSearchV2({ query: "DeckBackup", options: { "order_by": { ".tag": "last_modified_time" } } });
		} catch (e) {
			console.log(4)
			await this.checkRequest(e as DropboxResponseError<object>)
			return e as string;
		}

		if (!response.result.matches[0]) { return "DeckBackup1.json"; }

		let backups = response.result.matches;

		let backupIndex = 1;
		if (backups.length > 0) {
			let metadata = (backups[0].metadata as any).metadata as files.FileMetadata;
			backupIndex = parseInt(metadata.name.substring("DeckBackup".length, "DeckBackup".length + 1), 10);
		}

		if (backupIndex === 7) {
			backupIndex = 0; //We will always increment it by 1 below
		}

		backupIndex++;

		return `DeckBackup${backupIndex}.json`;
	}

	async RefreshAccessToken(): Promise<void> {
		let refreshToken = await localforage.getItem<string>(dataKeys.REFRESHTOKEN);
		let refreshExpires = await localforage.getItem<number>(dataKeys.REFRESHTIME);
		let lastRefresh = await localforage.getItem<number>(dataKeys.LASTREFRESHTIME);

		if (refreshExpires === null || lastRefresh === null || refreshToken === null) {
			return;
		}

		let refreshDate = lastRefresh + (refreshExpires * 1000);

		if (refreshDate < new Date().getTime()) {

			let auth = new DropboxAuth({ clientId: "2ar4lg23id993vu" });

			auth.setRefreshToken(refreshToken);

			let result = await auth.refreshAccessToken();

			console.log(result);

			await localforage.setItem(dataKeys.LASTREFRESHTIME, new Date().getTime());
		}
	}

	async checkRequest(response: any): Promise<void> {

		console.log(response.status)

		switch (response.status) {
			case 400:
			case 401:
			case 403:

				if (response.error.error) {
					console.log(response.error.error[".tag"])
					if (response.error.error[".tag"] === "expired_access_token") {
						await this.RefreshAccessToken();
						break
					}
				}

				let value = await Swal.fire({
					"title": "Attention",
					"text": "Dropbox is your sync provider and is requiring you to re-authenticate your account.",
					showCancelButton: true,
					allowOutsideClick: false,
					confirmButtonText: 'Re-Authenticate',
					cancelButtonText: 'Remove Dropbox as sync provider',
				});
				if (value.isConfirmed === true) {

					await this.auth()

					break;

				} else {
					DeleteBackupProvider()
				}
		}
	}
}