import React /*, { Component }*/  from 'react';

//Switches between Graphic Only Cards and SVG Cards RNH 20210910
export function GenerateCard(props){
  if(props.graphic_only){
    return <img alt="" {...props} />
  } else { 
  return RenderSVG(props)};
};

//This code renders a virtual card with four inputs letter, letterfont, year and yearfont J 20210910
export function RenderSVG(props) {
    
    return (
      <div className="border border-dark shadow-lg rounded h-100 d-block m-auto card-img">
      <svg version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 305.28 396"
        style={{ enableBackground: "new 0 0 305.28 396" }}>
        <style type="text/css" dangerouslySetInnerHTML={{
          __html:
            `.st0{font-family:${props.letterfont};}
          .st1{font-size:9px;}
          .st2{font-size:${props.letterfontsize}}
          .st3{fill:#FFFFFF;}
          .st4{clip-path:url(#SVGID_2_);}
          .st5{font-family:Times-Roman;}
          .basicFont{font-family:TimesNewRomanPSMT;font-size:100px}
          .kindergartenFont{font-family:schooltextbold;font-size:140px}
          `}}
        />
        <switch>
          <g>
            <text x="50%" y="50%" letterSpacing={`${props.letter==="fl"?"10":"0"}`} className={`st0 st2 ${props.basicFont?"basicFont":"kindergartenFont"}`} dominantBaseline="middle" textAnchor="middle">{props.letter==="ie=i"?"ie":props.letter}</text>
            <g>
              <defs>
                <rect id="SVGID_1_" x="269.89" y="371.71" width="35.39" height="24.29" />
              </defs>
              <g className="st4">
                <text transform="matrix(1 0 0 1 269.8848 378.4619)" className="st5 st1">2022</text>
              </g>
            </g>
          </g>
        </switch>
      </svg>
      </div>
      );
  }

  