import localforage from "localforage";
import Papa from "papaparse";
import { SaveLoadDecks } from "./SaveLoadDecks";
//import moment from "moment";
import Swal from "sweetalert2";
import CardDeckRules from "./Rules";
//import { BackupProviders, getBackupProvider, RunBackup } from "./SyncSettings/Preferences";
//import ReactDOM from 'react-dom';
import sync from "./SyncSettings/Sync";
const files = {
	FINALBOARD: "./assets/csv/Finalboard.csv"
};

export const dataKeys = {
	DECKS: "decks",
	WORDS: "words",
	REFRESHTIME: "refreshTime",
	LASTREFRESHTIME: "lastRefreshTime",
	CURRENTVERSION: "currentVersion",
	LASTFILEBACKUP: "lastFileBackup",
	CURRENTRULESET: "currentRuleSets",
	BACKUPAUTHTOKEN: "backupAuthToken",
	BACKUPPROVIDER: "backupProvider",
	BACKUPINDEX: "backupIndex",
	BACKUPDATETIME: "backupDateTime",
	GOOGLEBACKUPID: "googleBackupId",
	REFRESHTOKEN: "refreshToken",
	DBCLOUDDATA: "dbCloudData",
	CODEVERIFER: "codeVerifier",
	USEREMAIL: "userEmail",
	INITIALGMAIL: "initialGmail",
};

class Card {
	constructor(data, position) {
		this.type = data[`Position_${position}_Card_Type`]; //Position_{x}_Card_Type
		if (data[`P_${position}_Card_Type_Rev`]) {
			this.type_rev = data[`P_${position}_Card_Type_Rev`]; //Position_{x}_Card_Type
		}
		this.taps = data[`Position_${position}_Taps`]; //Position_{x}_Taps
		this.cardselected = data[`Position_${position}_Card_Selected`]; //Position_{x}_Card_Selected
		this.additions = data[`Position_${position}_Additions`]; //Position_{x}_Additions
		this.position = data[`Position_${position}_Position`]; //Position_{x}_Position
		this.list = data[`Position_${position}_List`]; //Position_{x}_List
		this.folder = data[`Position_${position}_Folder`]; //Position_{x}_Folder
		this.face = data[`Position_${position}_Card_Face`]; //Position_{x}_Card_Face
		this.presentface = this.face === "blank" ? "" : this.face;


		/**
		 * This section would take the Position_${position}_Card_Number column from the
		 * CSV and make sure it had two digits following the decimal.
		 * 1.01	=>	1.01
		 * 1.1	=>	1.10
		 * We removed this function however in favor of making sure that Excel exports as text
		 */
		/*if (data[`Position_${position}_Card_Number`]) {
			if (data[`Position_${position}_Card_Number`].substr(0, 1) !== "p") {
				this.cardnumber = (data[`Position_${position}_Card_Number`] + "0").substr(0, (data[`Position_${position}_Card_Number`].indexOf(".") + 3)); //Position_{x}_Card_Number
			} else {
				this.cardnumber = data[`Position_${position}_Card_Number`];
			}
		}*/

		this.cardnumber = data[`Position_${position}_Card_Number`];
		this.name = data[`Position_${position}_Card_Name`]; //Position_{x}_Card_Name
		this.imgsrc = `./assets/cards/${this.cardnumber}.png`;
	}
}

class Word {
	constructor(data) {

		let isGraphicOnly = "FALSE";

		if (data.Is_Graphic_only.trim("")) {
			isGraphicOnly = data.Is_Graphic_only;
		}

		this.graphic_only = ((isGraphicOnly.toUpperCase().trim() === "TRUE") || (isGraphicOnly.toUpperCase().trim() === "1"))

		this.word = data.word; //word
		this.special_rule = data.Special_Rule; //Special_Rule

		if (data.Special_Rule_Rev_A) {
			this.special_rule_rev_a = data.Special_Rule_Rev_A; //Special_Rule_Rev
		}

		if (data.Special_Rule_Rev_B) {
			this.special_rule_rev_b = data.Special_Rule_Rev_B; //Special_Rule_Rev
		}

		this.carddeck = data.Card_Deck; //Card_Deck
		this.syllable_type = data.Syllable_Type; //Syllable_Type		
		this.card3 = new Card(data, 3);
		this.card2 = new Card(data, 2);
		this.card1 = new Card(data, 1);
	}
}

export function keycombinations(set) {
	return (function acc(xs, set) {
		let x = xs[0];
		if (typeof x === "undefined")
			return set;
		for (let i = 0, l = set.length; i < l; ++i)
			set.push(set[i].concat(x));
		return acc(xs.slice(1), set);
	})(set, [[]]).slice(1);
};

export function MergeBaseRules(ruleSet) {
	if (ruleSet === undefined) { return; }

	let tmpRuleSet = JSON.parse(JSON.stringify(ruleSet));
	let tmpFullDeck = JSON.parse(JSON.stringify(CardDeckRules));

	if (ruleSet.Syllable) {
		for (let key in ruleSet.Syllable.categories) {
			if ((!tmpRuleSet.Syllable.categories[key]) || (!tmpFullDeck.Syllable.categories[key])) {
				continue; //This is in case they have a legacy deck that does not contain the key any longer
			}

			if (tmpFullDeck.Syllable.categories[key].value_rewrite) {
				for (let rewriteKey in tmpFullDeck.Syllable.categories[key].value_rewrite) {
					let rewriteValueIndex = tmpRuleSet.Syllable.categories[key].values.indexOf(rewriteKey);
					if (rewriteValueIndex !== -1) {
						ruleSet.Syllable.categories[key].values[rewriteValueIndex] = tmpFullDeck.Syllable.categories[key].value_rewrite[rewriteKey];
					}
				}
			}

			tmpRuleSet.Syllable.categories[key] = tmpFullDeck.Syllable.categories[key];
			tmpRuleSet.Syllable.categories[key].values = ruleSet.Syllable.categories[key].values;
		}
	}

	if (ruleSet.Consonants) {
		for (let key in ruleSet.Consonants.categories) {
			if ((!tmpRuleSet.Consonants.categories[key]) || (!tmpFullDeck.Consonants.categories[key])) {
				continue; //This is in case they have a legacy deck that does not contain the key any longer
			}

			if (tmpFullDeck.Consonants.categories[key].value_rewrite) {
				for (let rewriteKey in tmpFullDeck.Consonants.categories[key].value_rewrite) {
					let rewriteValueIndex = tmpFullDeck.Consonants.categories[key].values.indexOf(rewriteKey);
					if (rewriteValueIndex !== -1) {
						ruleSet.Consonants.categories[key].values[rewriteValueIndex] = tmpFullDeck.Consonants.categories[key].value_rewrite[rewriteKey];
					}
				}
			}

			tmpRuleSet.Consonants.categories[key] = tmpFullDeck.Consonants.categories[key];
			tmpRuleSet.Consonants.categories[key].values = ruleSet.Consonants.categories[key].values;
		}
	}

	if (ruleSet.Visual) {
		for (let key in ruleSet.Visual.categories) {

			if ((!tmpRuleSet.Visual.categories[key]) || (!tmpFullDeck.Visual.categories[key])) {
				continue; //This is in case they have a legacy deck that does not contain the key any longer
			}

			if (tmpFullDeck.Visual.categories[key].value_rewrite) {
				for (let rewriteKey in tmpFullDeck.Visual.categories[key].value_rewrite) {
					let rewriteValueIndex = tmpFullDeck.Visual.categories[key].values.indexOf(rewriteKey);
					if (rewriteValueIndex !== -1) {
						ruleSet.Visual.categories[key].values[rewriteValueIndex] = tmpFullDeck.Visual.categories[key].value_rewrite[rewriteKey];
					}
				}
			}

			tmpRuleSet.Visual.categories[key] = tmpFullDeck.Visual.categories[key];
			tmpRuleSet.Visual.categories[key].values = ruleSet.Visual.categories[key].values;
		}
	}

	return tmpRuleSet;
}

export function RemoveAllDecks() {
	Swal.fire({
		title: "Are you sure?",
		text: "This will remove all of the configurations you have created. The only way to restore the configurations is from a backup file. Are you sure you want to do this?",
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: "Yes"
	}).then((result) => {

		if (result.value) {
			localforage.getItem(dataKeys.DECKS).then((tmpDecks) => {
				if (!tmpDecks) { return }
				for (var i = 0; i < tmpDecks.length; i++) {
					if (!tmpDecks[i].lessonDeck) {
						tmpDecks[i].deleted = -1 //perm deleted

						for (let key in tmpDecks[i]) {
							switch (key) {
								case "modified":
									tmpDecks[i].modified = (new Date()).getTime()
									break
								case "title":
								case "normalized_name":
								case "created":
								case "deleted":
									continue;
								default:
									delete tmpDecks[i][key];
							}
						}
					}
				}

				localforage.setItem(dataKeys.DECKS, tmpDecks).then(() => {
					let syncer = new sync();
					syncer.push(false).then(() => {
						Swal.fire({
							title: "Deleted!",
							text: "All decks have been removed",
							icon: "success"
						}).then((result) => {


							window.location.reload();
						})
					});
				});

			})
		}

	})

}

export function SwabTheDecks(cb) {
	localforage.getItem(dataKeys.DECKS).then((decks) => {

		if (!decks || typeof decks.length === 'undefined') {
			cb();
			return;
		}

		let newDeck = decks.filter((deck) => {
			return ((deck.title) && (deck.title !== ""));
		});

		newDeck = newDeck.map((deck, newDeckIndex) => {
			if ((!deck.normalized_name) || (!CheckValidDeckName(deck.title))) {
				let index = 1;
				deck.normalized_name = NormalizeDeckName(deck.title, index);

				for (let deckIndex = 0; deckIndex < newDeck.length; deckIndex++) {
					if (newDeckIndex === deckIndex) {
						continue;
					}

					if (newDeck[deckIndex].normalized_name === deck.normalized_name) {
						index++;
						deck.normalized_name = NormalizeDeckName(deck.title, index);
						while (newDeck[deckIndex].normalized_name === deck.normalized_name) {
							index++;
							deck.normalized_name = NormalizeDeckName(deck.title, index);
						}
						deckIndex = -1;//We now must look through the newDeck until we are absolutely sure there is no match
					}
				}
			}

			addFontVariable(deck);

			return deck;
		});

		localforage.setItem(dataKeys.DECKS, newDeck).then(() => {
			if (cb) {
				cb();
				return;
			}
			Swal.fire({
				title: "Finished",
				text: "All of your decks have been cleaned up",
				icon: "success"
			});
		});
	});
}

export function PopulateWordsDB(force, showComplete, cb) {
	let self = {};
	self.Words = [];

	if (showComplete instanceof Function) {
		cb = showComplete;
		showComplete = false;
	}

	if (!cb) { cb = function () { } }

	self._checkFileExists = async function (file) {

		let response = await fetch(file, {
			method: "HEAD"
		});

		return response.ok;
	}

	self._processCsvResults = function () {
		let config = {
			delimiter: "",
			//newline: "\\n",
			escapeChar: "\"",
			dynamicTyping: false,
			header: true,
			download: true,
			stream: true,
			skipEmptyLines: true,
			transform: function (value) {
				if (value) {
					return value.toLowerCase();
				}
				return value;
			},
			delimitersToGuess: [","],
			step: function (results) {
				self.Words.push(new Word(results.data));
			},
			complete: function (results) {
				localforage.setItem(dataKeys.WORDS, self.Words).then(cb);

				if (showComplete) {
					Swal.fire({
						title: "Finished",
						text: "All of the words have been imported",
						icon: "success"
					});
				}

				//cb();
			},
		}

		Papa.parse(files.FINALBOARD, config);
	}

	localforage.getItem(dataKeys.WORDS)
		.then(function (words) {
			if ((words === null) || force) {
				if (!self._checkFileExists(files.FINALBOARD)) {
					//Do dropbox download
				}

				self._processCsvResults();
			}
		});
}

export function CheckValidDeckName(name) {
	let validCharRegEx = /([a-zA-Z0-9_-]+)/g;

	return validCharRegEx.test(name); //["", "<validChars>", ""]
}

export function NormalizeDeckName(name, index) {
	let invalidCharRegEx = /([^a-zA-Z0-9_-]+)/g;
	name = name.replace(invalidCharRegEx, "_");

	return `${name}-${index}`;
}

//temp function add font varialbe will be replaced when we have a function test versions of decks
export function addFontVariable(testDeck) {
	if (typeof testDeck.basicFont === 'undefined') {
		testDeck.basicFont = true
	};
	return testDeck
}

export async function mergeDecks(sourceDeck, importDeck, sync) {
	let merge = JSON.parse(JSON.stringify(sourceDeck));

	if (!sync) { sync = false }

	for (let index = 0; index < importDeck.length; index++) {
		let sourceIndex = sourceDeck.findIndex((el) => {
			return (el.title.toLowerCase()) === (importDeck[index].title.toLowerCase())
		});

		if (sourceIndex > -1) {
			if (sync) {

				if (importDeck[index].modified >= sourceDeck[sourceIndex].modified) {
					merge[sourceIndex] = importDeck[index]
				};

				continue;
			}
			if (!sync && importDeck[index].deleted !== -1 && !importDeck[index].lessonDeck) {
				if (sourceDeck[sourceIndex].deleted !== -1 && !sourceDeck[sourceIndex].lessonDeck) {
					await Swal.fire({
						title: "Deck Already Exists",
						html: `A deck titled ${merge[sourceIndex].title} currently exists. Do you want to replace this deck?<br />(If you choose cancel you can change the title and try again)`,
						icon: "warning",
						showCancelButton: true
					});
				}
				importDeck[index].modified = new Date().getTime()
				merge[sourceIndex] = importDeck[index];
			}

			if(importDeck[index].lessonDeck){
				merge.push(importDeck[index])
			}

		} else {
			merge.push(importDeck[index])
		}
	}
	return merge;
}