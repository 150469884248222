import localforage from "localforage";
import moment from "moment";
import Swal from "sweetalert2";

import { dataKeys, mergeDecks } from "../Globals";
import { BackupProviders } from "./Preferences";

export default class sync {
    //fields
    provider;
    localIndex = 1;
    providerDependencies = {};
    constructor() {
        this.getBackupProvider().then((provider) => {
            this.provider = provider
            //this.updateIndex()
            for (let key in this.providerDependencies) {
                this.providerDependencies[key]()
                delete this.providerDependencies[key];
            }
        })
    }

    //Methods

    async getBackupProvider() {
        let provider = await localforage.getItem(dataKeys.BACKUPPROVIDER);

        if (provider) {
            return provider;
        };
    }

    async push(willPull) {
        //this.updateIndex()
        if (! await localforage.getItem(dataKeys.DECKS)) { return }
        if (!this.provider) {
            this.providerDependencies["push"] = this.push.bind(this, [willPull]);
            return

        }
        await BackupProviders[this.provider].backup(false)
        await localforage.setItem(dataKeys.BACKUPDATETIME,new moment().format("LLL"))
        if (await BackupProviders[this.provider].synccheck() === true) { return };

        await BackupProviders[this.provider].setup();
        //await this.pull();

    }

    async pull() {
        /*
        if (!this.provider) {
            this.providerDependencies["pull"] = this.pull.bind(this);
            return
        }
        */
        this.provider = await this.getBackupProvider()
        if (!this.provider) { return };

        await BackupProviders[this.provider].setup();

        let data = await BackupProviders[this.provider].sync();

        await this.checkCloudvsLocal(data);

    }

    async checkCloudvsLocal() {
        let cloudData = await localforage.getItem(dataKeys.DBCLOUDDATA)
      
        if (!cloudData) { return };

        if (cloudData.length === 0) {
            Swal.fire({
                title: "Bad Sync File",
                text: `No new configurations loaded. Perhaps the deck file is corrupted?`,
                icon: "error"
            });

            return;
        }

        let decks = await localforage.getItem(dataKeys.DECKS);

        if ((!decks) || (decks.length === 0)) {

            await localforage.setItem(dataKeys.DECKS, cloudData)
            return
        }
        
        let newDecks = await mergeDecks(decks, cloudData, true);
      
        await localforage.setItem(dataKeys.DECKS, newDecks);
        await localforage.removeItem('dbCloudData');
    }



    updateIndex() {
        if (this.provider) {
            BackupProviders[this.provider].setup().then(() => {
                BackupProviders[this.provider].fileName(this.mostrecentFileName.bind(this))
            })
        }
    }
    async mostrecentFileName(cloudName) {
        this.localIndex = await parseInt(cloudName.substring(10, 11), 10)
        await localforage.setItem(dataKeys.BACKUPINDEX, this.localIndex);
    }

}