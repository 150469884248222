import React from "react";
import { Line, Stage, Image, Layer } from "react-konva";
import letters from "./LetterDrawings.json";
import * as ImageShape from "konva/lib/shapes/Image";

interface AnimationBoardProps {
    Letter: string
}

interface AnimationBoardState {
    lines: number[][][];
    frames: { [key: string]: number[][], };
    frame_lines: number[][];
    image?: any;
    frameLoop: number;
    redrawCount: number;
    firstClickOccured: boolean;
}

export default class HWAnimationBoard extends React.Component<AnimationBoardProps, AnimationBoardState> {

    card?: ImageShape.Image | null;
    isDrawing: boolean = false;
    fps: number = 15;
    totalAnimationTime: number = 11;
    redrawLimit: number = 3;
    cardAnimationTimes: { [key: string]: number,}={"UA":9, "UB":9, "UC":3, "UD":7, "UE":11, "UF":9, "UG":7, "UH":9, "UI":9, "UJ":7, "UK":7, "UL":4, "UM":7, "UN":7, "UO":3, "UP":7, "UQ":6, "UR":8, "US":5, "UT":6, "UU":5, "UV":4, "UW":5, "UX":6, "UY":9, "UZ":6, "La":4, "Lb":5, "Lc":3, "Ld":4, "Le":5, "Lf":7, "Lg":5, "Lh":6, "Li":5, "Lj":6, "Lk":7, "Ll":3, "Lm":7, "Ln":5, "Lo":4, "Lp":6, "Lq":5, "Lr":5, "Ls":5, "Lt":5, "Lu":5, "Lv":4, "Lw":5, "Lx":6, "Ly":8, "Lz":5};

    constructor(props: AnimationBoardProps) {
        super(props);

        this.state = {
            lines: [[]],
            frames: {},
            frame_lines: [],
            frameLoop: 0,
            redrawCount: 0,
            firstClickOccured: false
        };
    }

    async componentDidMount(): Promise<void> {
        //this.loadImage();

        let storedFrames = (letters as { [key: string]: number[][], });
        //let storedFrames = await localforage.getItem<{ [key: string]: number[][], }>(STORAGE_KEY);

        if (storedFrames) {
            this.setState({ frames: storedFrames });

            if (storedFrames.hasOwnProperty(this.props.Letter)) {
                this.totalAnimationTime = this.cardAnimationTimes[this.props.Letter]
                this.setState({ frame_lines: storedFrames[this.props.Letter] });
            } else {
                this.setState({ frame_lines: [] });
            }
        }
    }

    applyCache() {
        if (!this.card) { return; }

        this.card.cache()
    }

    async componentDidUpdate(prevProps: Readonly<AnimationBoardProps>, prevState: Readonly<AnimationBoardState>, snapshot?: any): Promise<void> {
        if (prevProps.Letter !== this.props.Letter||this.redrawLimit == this.state.redrawCount) {
            //this.loadImage();

            this.isDrawing = false;

            if (this.state.frames.hasOwnProperty(this.props.Letter)) {
                this.totalAnimationTime = this.cardAnimationTimes[this.props.Letter]
                this.setState({ frame_lines: this.state.frames[this.props.Letter], lines: [[]], frameLoop: 0, redrawCount: 0, firstClickOccured: false });
            } else {
                this.setState({ frame_lines: [], lines: [[]], frameLoop: 0, redrawCount: 0, firstClickOccured: false });
            }
        }
    }

    loadImage = () => {
        const image = new window.Image();
        image.src = `./assets/cards/HW${this.props.Letter}.svg`;
        image.onload = () => {
            this.setState({
                image
            }, this.applyCache.bind(this));
        }
    }

    startDrawing(e: React.MouseEvent<HTMLImageElement>) {
        if (this.isDrawing || this.state.redrawCount === this.redrawLimit) { return; }

        this.isDrawing = true;
        let redrawCount = this.state.redrawCount;
        let lines = this.state.lines;
        
        
        
        if (this.state.firstClickOccured) {
            redrawCount++;
            lines.push([]);
        }
        console.log(redrawCount, this.state.redrawCount, this.state.lines);

        this.setState({ frameLoop: 0, lines: lines, firstClickOccured: true, redrawCount: redrawCount }, this.addFrames.bind(this));
    }

    addFrames() {
        if (!this.isDrawing) { return; }

        let loop = this.state.frameLoop;
        let shouldKeepDrawing = true;

        console.log(this.state.redrawCount);

        if (this.state.lines[this.state.redrawCount].length === this.state.frame_lines.length) {
            if (this.state.lines[this.state.redrawCount][this.state.frame_lines.length - 1].length === this.state.frame_lines[this.state.frame_lines.length - 1].length) {
                if (this.state.redrawCount !== this.redrawLimit) {
                    this.isDrawing = false;
                }
                shouldKeepDrawing = false;
            }
        }

        if (!shouldKeepDrawing) { return; }

        let currentFullLines = this.state.frame_lines;
        let currentLines = this.state.lines[this.state.redrawCount];

        let currentLineSetIndex = currentLines.length > 0 ? currentLines.length - 1 : 0

        if (currentLines[currentLineSetIndex] && currentLines[currentLineSetIndex].length === currentFullLines[currentLineSetIndex].length) {
            currentLineSetIndex++;
            loop = 0;
        }

        let currentFullLineSet = currentFullLines[currentLineSetIndex];
        let currentLineSet = currentLines[currentLineSetIndex];

        let totalFrames = this.fps * this.totalAnimationTime;
        let frameSetLength = currentFullLines.flat().length;
        let framesToGrab = Math.ceil(frameSetLength / totalFrames);

        if (framesToGrab % 2 === 1) {
            framesToGrab++;
        }

        if (!currentLineSet) {
            currentLineSet = currentFullLineSet.slice(framesToGrab * loop, framesToGrab * (loop + 1)).map((point, index) => {
                //scale to canvas size
                point *= .28//0.239393939393939;
                if ((index % 2) === 0) {
                    point += (230 * this.state.redrawCount)
                }
                return point;
            });
            currentLines.push(currentLineSet);
            loop++;

            this.setState((state) => {
                state.lines[this.state.redrawCount] = currentLines;

                return {
                    frameLoop: loop,
                    lines: state.lines
                }
            }, () => {
                setTimeout(this.addFrames.bind(this), 1000 / this.fps);
            });

            /*this.setState({ frameLoop: loop, lines: currentLines }, () => {
                setTimeout(this.addFrames.bind(this), 1000 / this.fps);
            });*/

            return;
        }

        currentLineSet = currentFullLineSet.slice(framesToGrab * loop, framesToGrab * (loop + 1)).map((point, index) => {
            //scale to canvas size
            point *= .28//0.239393939393939;
            if ((index % 2) === 0) {
                point += (230 * this.state.redrawCount)
            }
            return point;
        });

        currentLines[currentLineSetIndex] = currentLines[currentLineSetIndex].concat(currentLineSet);

        loop++;
        this.setState((state) => {
            state.lines[this.state.redrawCount] = currentLines;

            return {
                frameLoop: loop,
                lines: state.lines
            }
        }, () => {
            setTimeout(this.addFrames.bind(this), 1000 / this.fps);
        });
        /*this.setState({ frameLoop: loop, lines: currentLines }, () => {
            setTimeout(this.addFrames.bind(this), 1000 / this.fps);
        });*/
    }

    opacityFilter(imageData: ImageData) {
        let nPixels = imageData.data.length;
        for (var i = 0; i < nPixels; i += 4) {
            let currentPixelSum = imageData.data.slice(i, i + 3).reduce((valA, valB) => (valA + valB));
            if (currentPixelSum === 765) {
                imageData.data[i + 3] = 0;
                continue;
            }
        }
        //console.log(imageData);
    }

    render() {
        return (<>
            <div className="col pr-0">
                <img onClick={this.startDrawing.bind(this)} style={{ "width": "11.5vw", "float": "right"}} src="assets/cards/watchme.png" alt="" />
            </div>
            <div className="col-10 pl-0 handwriting-line">
                <div className="handwriting-line-background">
                    <Stage
                        width={800}
                        height={235}
                    >
                        <Layer>
                            {this.state.lines.map((redraw, i) => {
                                return redraw.map((line, i) => {
                                    return (<Line
                                        key={i}
                                        points={line}
                                        stroke="#f00"
                                        strokeWidth={6}
                                        tension={0.5}
                                        lineCap="round"
                                        lineJoin="round"
                                    />);
                                })
                            })
                            }
                        </Layer>
                    </Stage>
                </div>
            </div>
        </>)
    }
}