import React from "react";
import localforage from "localforage";
import { Link } from "react-router-dom";
import "../App.css"
import { GenerateCard } from "./Cards";
import { TestBlending, TestVisual, MergeVisualCards } from "./CardFilters";
import { dataKeys, MergeBaseRules } from "./Globals";
import { TailSpin } from "react-loader-spinner";

export class BlendingDrill extends React.Component {
	render() {
		return <Drill type="blending" deck={this.props.params.deck} />;
	}
}

export class VisualDrill extends React.Component {
	FilterCards() {

	}

	render() {

		return <Drill type="visual" deck={this.props.params.deck} />;
	}
}

class Drill extends React.Component {
	constructor(props) {
		super(props);

		this.words = [];

		this.state = {
			filteredWords: [],
			currentIndex: 0,
			previousIndex: [],
			lastClickedCard: 1,
			firstVisualRun: true,
			cardTaps: 0,
			currentRuleSet: {},
			continueMatchFace: null,
			continueMatchFacePosition: null,
			noDeckFound: false,
			filterFinished: false,
			forceType: null,
			forcePosition: null,
			permaDisableBlankThird: false,
			disableBlankThird: false,
			startCard: "./assets/cards/1.27.png"
		};

		document.body.addEventListener("keyup", this.HandleKeyUp.bind(this));
	}

	componentDidMount() {

		let url = new URL(window.location);
		url.searchParams.delete("code");
		window.history.pushState({}, "", url);


		localforage.getItem(dataKeys.WORDS).then((words) => {
			this.words = words;




			localforage.getItem(dataKeys.DECKS)
				.then((decks) => {

					if (!decks) {
						this.setState({ noDeckFound: true });
						return;
					}

					let ruleSet = {};

					for (let deckIndex = 0; deckIndex < decks.length; deckIndex++) {
						if (decks[deckIndex].normalized_name === this.props.deck) {
							ruleSet = MergeBaseRules(decks[deckIndex]);

							if (decks[deckIndex].normalized_name.substr(0, 2) === 'K_' && decks[deckIndex].lessonDeck) {
								this.setState({ startCard: "./assets/cards/C2B0K.png" })
							}

							this.setState({ currentRuleSet: ruleSet }, this.FilterCards.bind(this));
							//this.FilterCards();

							return;
						}
					}

					this.setState({ noDeckFound: true });
				});
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		//return (this.state.currentIndex < 1) ||  (nextState.currentIndex !== this.state.currentIndex);
		return true;
	}

	HandleKeyUp(key) {
		if (!this.props.type.toUpperCase() === "BLENDING") {
			return;
		}

		switch (key.keyCode) {
			case 39: //ArrowRight
				//*Needs to be defind*//
				break;
			case 37: //"ArrowLeft"
				this.setState((state) => {
					let navIndex = state.previousIndex;
					if (navIndex.length === 0) { return; }
					let previous = navIndex.pop();
					return {
						currentIndex: previous,
						previousIndex: navIndex,
						//disableBlankThird: this.props.type.toUpperCase() === 'BLENDING' && (this.state.filteredWords[previous].card3.face === 'blank' || this.state.filteredWords[previous].card1.face === 'blank')
					}
				});
				break;
			default:
				break;
		}
	}



	RenderBlend() {
		let disableThirdBlank = (
			this.state.filteredWords[this.state.currentIndex].card1.face === "blank" ||
			this.state.filteredWords[this.state.currentIndex].card3.face === "blank" ||
			this.state.permaDisableBlankThird
		);

		return (

			<div className="deck-cards">
				<div className="row">
					<div className="col" onClick={this.showNextCard.bind(this, 1)}>
						{/*<img alt="" className="border border-dark shadow-lg rounded h-100 d-block m-auto card-img" src={this.state.filteredWords[this.state.currentIndex].card1.imgsrc} onClick={this.showNextCard.bind(this, 1)} />*/}
						<GenerateCard letter={this.state.filteredWords[this.state.currentIndex].card1.presentface} basicFont={this.state.currentRuleSet.basicFont} />
					</div>
					<div className="col" onClick={this.showNextCard.bind(this, 2)}>
						{/*<img alt="" className="border border-dark shadow-lg rounded h-100 d-block m-auto card-img" src={this.state.filteredWords[this.state.currentIndex].card2.imgsrc} onClick={this.showNextCard.bind(this, 2)} />*/}
						<GenerateCard letter={this.state.filteredWords[this.state.currentIndex].card2.presentface} basicFont={this.state.currentRuleSet.basicFont} />
					</div>
					<div className="col" onClick={this.showNextCard.bind(this, 3)}>
						{/*<img alt="" className="border border-dark shadow-lg rounded h-100 d-block m-auto card-img" src={this.state.filteredWords[this.state.currentIndex].card3.imgsrc} onClick={this.showNextCard.bind(this, 3)} />*/}
						<GenerateCard letter={this.state.filteredWords[this.state.currentIndex].card3.presentface} basicFont={this.state.currentRuleSet.basicFont} />
					</div>
				</div>
				<div className="row">
					<div className="col"></div>
					<div className="col"></div>
					<div className="col">
						<button className="btn btn-light m-auto  d-block" onClick={this.forcecard.bind(this, "blank", 3)} disabled={disableThirdBlank}>Blank</button>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<h6 className="text-muted">{this.state.currentRuleSet.title}</h6>
					</div>
				</div>
			</div>
		);
	}

	RenderVisual() {

		if (this.state.firstVisualRun) {
			return (
				<React.Fragment>
					<div className="deck-cards">
						<div className="row">
							<div className="col offset-md-4">
								<img alt="" className="border rounded h-100 d-block m-auto card-img" src={this.state.startCard} onClick={this.showNextCard.bind(this, -1)} />
							</div>
							<div className="col">
								<div className="drill-dots">
									<div className="dot-holder">
										<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<circle cx="8" cy="8" r="8" />
										</svg>
									</div>
									<div className="dot-holder">
										<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<circle cx="8" cy="8" r="8" />
										</svg>
									</div>
									<div className="dot-holder">
										<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<circle cx="8" cy="8" r="8" />
										</svg>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<h6 className="text-muted">{this.state.currentRuleSet.title}</h6>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}

		let currentIndex = this.state.currentIndex;
		let word = this.state.filteredWords[currentIndex];

		let card = word.card1;

		while ((card.face === "blank") || (card.face === "")) {
			currentIndex = (currentIndex === (this.state.filteredWords.length - 1) ? 0 : currentIndex + 1);
			word = this.state.filteredWords[currentIndex]
			card = word.card1;
		}

		return (
			<React.Fragment>
				<div className="row">
					<div className="col offset-md-4" onClick={this.showNextCard.bind(this, -1)}>
						{/*<img alt="" className="border rounded h-100 d-block m-auto card-img" src={word.card1.imgsrc} onClick={this.showNextCard.bind(this, -1)} />*/}
						<GenerateCard className="border rounded h-100 d-block m-auto card-img" letter={word.card1.presentface} src={word.card1.imgsrc} graphic_only={word.graphic_only} basicFont={this.state.currentRuleSet.basicFont} />
					</div>
					<div className="col">
						<div className="drill-dots">
							<div className="dot-holder">
								<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8" cy="8" r="8" />
								</svg>
							</div>
							<div className="dot-holder">
								<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8" cy="8" r="8" />
								</svg>
							</div>
							<div className="dot-holder">
								<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8" cy="8" r="8" />
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col"></div>
					<div className="col">
						<button className="btn btn-light m-auto  d-block" onClick={this.sendBackVisualCard.bind(this)} disabled={this.checkSendBack(this)}>+</button>
					</div>
					<div className="col"></div>
				</div>
				<div className="row">
					<div className="col">
						<h6 className="text-muted">{this.state.currentRuleSet.title}</h6>
					</div>
				</div>
			</React.Fragment>
		);

	}

	render() {
		/*if (this.state.noDeckFound) {
			return (<div className="d-flex justify-content-center pt-2 alert alert-warning">
				<p>You do not currently have any decks titled `{this.props.deck}`. Create a deck <Link to={location => ({ ...location, pathname: `/DeckConfiguration` })}>now</Link> or select another <Link to={location => ({ ...location, pathname: `/SaveLoadDecks` })}>deck</Link></p>
			</div>);
		}*/

		let render = (
			<TailSpin visible={true} wrapperClass="d-flex justify-content-center pt-2"></TailSpin>
		);

		if (this.state.noDeckFound) {
			return (<div className="d-flex justify-content-center pt-2 alert alert-warning">
				<p>The deck `{decodeURIComponent(this.props.deck)}` could not be found. Would you like to create it <Link to={location => ({ ...location, pathname: `/DeckConfiguration` })}>now</Link>?</p>
			</div>);
		}

		if ((this.state.filteredWords.length === 0) && (!this.state.filterFinished)) {
			return render;
		}

		if ((this.state.filteredWords.length === 0) && (this.state.filterFinished)) {
			return (<div className="d-flex justify-content-center pt-2 alert alert-warning">
				<p>The deck `{decodeURIComponent(this.props.deck)}` did not have any words that match the selected rules. You can edit this deck deck <Link to={location => ({ ...location, pathname: `/DeckConfiguration/${encodeURIComponent(this.props.deck)}` })}>now</Link> or select another <Link to={location => ({ ...location, pathname: `/SaveLoadDecks` })}>deck</Link></p>
			</div>);
		}

		switch (this.props.type.toUpperCase()) {
			case "BLENDING":
				render = this.RenderBlend();
				break;
			case "VISUAL":
				render = this.RenderVisual();
				break;
			default:
				break;
		}

		return render;
	}

	forcecard(type, position) {
		this.setState({ forceType: type, forcePosition: position }, this.showNextCard.bind(this, position));
	}

	sendBackVisualCard() {

		let counter = 0
		this.setState((state) => {
			counter++
			if (counter < 2) {
				var sendto
				let delta = state.filteredWords[state.currentIndex]

				if(state.filteredWords.length-state.currentIndex>=3){sendto = 3}else{sendto=state.filteredWords.length+1}
				state.filteredWords.splice(state.currentIndex + sendto, 0, delta)
				return state

			}
		});

	}


	checkSendBack() {
		if(this.state.filteredWords.length-this.state.currentIndex==1){return true}
		let cNum = this.state.filteredWords[this.state.currentIndex].card1.cardnumber
		var cNumCnt = 0
		console.log(this.state.filteredWords.length-this.state.currentIndex,'line 344')
		
		for (var i = 0; i < this.state.filteredWords.length; i++) {

			let cNum2 = this.state.filteredWords[i].card1.cardnumber

			if (cNum === cNum2) { cNumCnt = cNumCnt + 1 }

		}

		if (cNumCnt > 1) { return true } else { return false };
	}

	showNextCard(cardPosition, evt) {
		/*
		When they are selecting a card they are locking the other two cards
		Filter down the list to match words based on the other two cards plus blank
		If they have only 1 word left and click a word 2 times then you reset the list back to no filters
		*/
		this.setState((state) => {
			let navIndex = state.previousIndex;
			navIndex.push(state.currentIndex);

			return {
				previousIndex: navIndex,
				lastClickedCard: cardPosition
			}
		});

		if ((cardPosition === -1) && (this.state.firstVisualRun)) {
			this.setState((state) => ({
				firstVisualRun: false,
				currentIndex: 0
			}));

			return;
		}

		if (this.props.type.toUpperCase() === "VISUAL") {
			this.setState((state) => ({
				firstVisualRun: (state.currentIndex === (state.filteredWords.length - 1)),
				currentIndex: (state.currentIndex === (state.filteredWords.length - 1) ? 0 : state.currentIndex + 1)
			}));

			return;
		}

		let currentTapCount = this.state.cardTaps;

		currentTapCount++;

		let remainingFaces = [1, 2, 3].filter((number) => { return (number !== cardPosition); });

		let currentIndex = this.state.currentIndex;
		let currentWord = this.state.filteredWords[currentIndex];
		let currentRemainingFirstFace = currentWord[`card${remainingFaces[0]}`].face;
		let currentRemainingSecondFace = currentWord[`card${remainingFaces[1]}`].face;

		let matchingFaces = [false, false, false];

		switch (cardPosition) {
			case 1:
				matchingFaces[0] = true;
				break;
			case 2:
				matchingFaces[1] = true;
				break;
			case 3:
				matchingFaces[2] = true;
				break;
			default:
				return;
		}

		let indexWords = this.state.filteredWords.map((word, index) => { word.index = index; return word; });

		let matchingWords = indexWords.filter((word) => (
			((currentRemainingFirstFace === word[`card${remainingFaces[0]}`].face) && (currentRemainingSecondFace === word[`card${remainingFaces[1]}`].face))
			|| ((currentRemainingFirstFace === word[`card${remainingFaces[0]}`].face) && ((cardPosition === 2) && ((word[`card${remainingFaces[1]}`].face === "blank") || ((this.state.continueMatchFace) && (this.state.continueMatchFacePosition === 3) && (word[`card${remainingFaces[1]}`].face === this.state.continueMatchFace)))))
			|| (((cardPosition === 2) && ((word[`card${remainingFaces[0]}`].face === "blank") || ((this.state.continueMatchFace) && (this.state.continueMatchFacePosition === 1) && word[`card${remainingFaces[0]}`].face === this.state.continueMatchFace))) && (currentRemainingSecondFace === word[`card${remainingFaces[1]}`].face))
		));

		if ((this.state.forceType) && (this.state.forceType === "blank")) {
			matchingWords = matchingWords.filter((word) => (
				word[`card${this.state.forcePosition}`].face === "blank"
			));
			//create a list keeping card 2 and forcing card 3 blank
			this.setState({ forceType: null });
			if (matchingWords.length === 0) {
				matchingWords = indexWords.filter((word) => (
					word[`card${this.state.forcePosition}`].face === "blank"
					&& word.card2.face === currentWord.card2.face
				));
			}
			//start with all words with blank 3rd card if no list from card 2 and blank 3rd
			if (matchingWords.length === 0) {
				matchingWords = indexWords.filter((word) => (
					word[`card${this.state.forcePosition}`].face === "blank"
				));
			}
		}

		let saveFace = (((cardPosition === 2) && (this.state.continueMatchFace)) ? this.state.continueMatchFace : null);
		let saveFacePosition = (((cardPosition === 2) && (this.state.continueMatchFace)) ? this.state.continueMatchFacePosition : null);
		let nextIndex = currentIndex;

		if ((matchingWords.length === 0) || ((matchingWords.length === 1) && (matchingWords[0].index === currentIndex))) {
			if (currentTapCount > 1) { //Pick a random index and restart
				nextIndex = Math.floor(Math.random() * this.state.filteredWords.length) + 0;

				this.setState((state) => ({
					cardTaps: 0,
					continueMatchFace: null,
					currentIndex: nextIndex,
					//disableBlankThird: this.props.type.toUpperCase() === 'BLENDING' && (this.state.filteredWords[nextIndex].card3.face === 'blank' || this.state.filteredWords[nextIndex].card1.face === 'blank')
				}));

				return;
			}

			this.setState((state) => ({ cardTaps: currentTapCount }));
		}

		let higherIndexes = matchingWords.filter((word) => (word.index > currentIndex));

		if (higherIndexes.length > 0) {
			nextIndex = higherIndexes[0].index;

			if (!this.state.continueMatchFace && cardPosition === 2 && this.state.filteredWords[nextIndex].card1.face === "blank") {
				saveFace = currentWord.card1.face;
				saveFacePosition = 1;
			}

			if (!this.state.continueMatchFace && cardPosition === 2 && this.state.filteredWords[nextIndex].card3.face === "blank") {
				saveFace = currentWord.card3.face;
				saveFacePosition = 3;
			}

			this.setState((state) => ({
				continueMatchFace: saveFace,
				continueMatchFacePosition: saveFacePosition,
				currentIndex: nextIndex,
				//disableBlankThird: this.props.type.toUpperCase() === 'BLENDING' && (this.state.filteredWords[nextIndex].card3.face === 'blank' || this.state.filteredWords[nextIndex].card1.face === 'blank')
			}));

			return;
		}

		nextIndex = matchingWords[0].index;

		if (!this.state.continueMatchFace && cardPosition === 2 && this.state.filteredWords[nextIndex].card1.face === "blank") {
			saveFace = currentWord.card1.face;
			saveFacePosition = 1;
		}

		if (!this.state.continueMatchFace && cardPosition === 2 && this.state.filteredWords[nextIndex].card3.face === "blank") {
			saveFace = currentWord.card3.face;
			saveFacePosition = 3;
		}

		this.setState((state) => ({
			continueMatchFace: saveFace,
			continueMatchFacePosition: saveFacePosition,
			currentIndex: nextIndex,
			//disableBlankThird: this.props.type.toUpperCase() === 'BLENDING' && (this.state.filteredWords[nextIndex].card3.face === 'blank' || this.state.filteredWords[nextIndex].card1.face === 'blank')
		}));
	}

	FilterCards() {
		let filteredConsonants = [];
		let shuffled = [];

		if (this.props.type.toUpperCase() === "BLENDING") {
			if (this.state.currentRuleSet.Consonants) {
				filteredConsonants = this.words.filter(TestBlending, { deck: this.state.currentRuleSet });
				shuffled = this._shuffle(filteredConsonants);
			}
		}


		if (this.props.type.toUpperCase() === "VISUAL") {
			let visualArray = MergeVisualCards(this.words);
			let filteredVisual = visualArray.filter(TestVisual, { deck: this.state.currentRuleSet });
			shuffled = this._shuffleVisual(filteredVisual);
		}

		let blankThirds = shuffled.filter((word) => (

			this.props.type.toUpperCase() === 'BLENDING' &&
			word.card3.face === "blank"

		));


		this.setState({ filteredWords: shuffled, filterFinished: true, permaDisableBlankThird: (blankThirds.length === 0), disableBlankThird: (blankThirds.length === 0) })

	}

	_shuffle(array) {
		let currentIndex = array.length, temporaryValue, randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {

			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	_shuffleVisual(array) {
		let groupings = [[], [], [], [], [], [], [], [], [], [], [], [], []];

		for (let arrayIndex = 0; arrayIndex < array.length; arrayIndex++) {
			switch (array[arrayIndex].card1.list.toLowerCase()) {
				case "primary picture deck":
					groupings[0].push(array[arrayIndex]);
					break;
				case "graphic cards":
					groupings[1].push(array[arrayIndex]);
					break;
				case "magic e cards":
					groupings[2].push(array[arrayIndex]);
					break;
				case "short vowel exceptions - visual tool":
					groupings[3].push(array[arrayIndex]);
					break;
				case "syllable division":
					groupings[4].push(array[arrayIndex]);
					break;
				case "c+le(consonant+le)":
					groupings[5].push(array[arrayIndex]);
					break;
				case "tools":
					groupings[6].push(array[arrayIndex]);
					break;
				case "prefixes & suffixes tools":
					groupings[7].push(array[arrayIndex]);
					break;
				case "prefixes":
					groupings[8].push(array[arrayIndex]);
					break;
				case "suffixes":
					groupings[9].push(array[arrayIndex]);
					break;
				case "other patterns":
					groupings[10].push(array[arrayIndex]);
					break;
				case "roots":
					groupings[11].push(array[arrayIndex]);
					break;
				default:
					groupings[12].push(array[arrayIndex]);
					break;
			}
		}

		for (let groupIndex = 0; groupIndex < groupings.length; groupIndex++) {
			let shuffledGroup = this._shuffle(groupings[groupIndex]);

			groupings[groupIndex] = shuffledGroup;
		}
		return groupings.flat();
	}
}