
/**
 * 
 * @param {Word} word
 * Test if the custom deck contains any Syllable rules and if so do all of the cards satisfy the selected options
 */
function TestSyllable(word, customDeck) {
	if (word.graphic_only) { return false; }

	if (customDeck.Syllable) {
		for (let categoryIndex in customDeck.Syllable.categories) {
			let category = customDeck.Syllable.categories[categoryIndex];

			if ((!category) || (!category.field)) {
				continue;
			}

			if (!word.syllable_type) {
				continue;
			}

			let categorySyllabyle = categoryIndex;

			if (category.syllable_type) {
				categorySyllabyle = category.syllable_type;
			}

			if (word.syllable_type.toLowerCase() !== categorySyllabyle.toLowerCase()) {
				continue;
			}

			for (let valueIndex = 0; valueIndex < category.values.length; valueIndex++) {
				for (let fieldIndex = 0; fieldIndex < category.field.length; fieldIndex++) {
					let field = category.field[fieldIndex];
					let fieldValue = category.values[valueIndex];
					let cardField = word[`card${field.card_position}`];
					let cardFieldValue = cardField[field.field];

					if (cardFieldValue.toLowerCase() === fieldValue.toLowerCase()) {
						return true;
					}
				}
			}
		}

		return false;
	}

	return false;
}

/**
 * 
 * @param {Word} word
 * Test if the custom deck contains any Consonant rules and if so do all of the cards satisfy the selected options
 * This starts with the special rule, then goes based on the card and the specified field from the CSV file
 * Then checks for any special rules for the category
 */
function TestConsonant(word, customDeck) {
	if (word.graphic_only) { return false; } //Should already be filtered but here for consistency-sake

	let specialRuleIndex = 0;
	let card1Index = 1;
	let card3Index = 2;

	let wordHasAllValues = [false, false, false]; //Special Rule, Card1 Match, Card3 Match

	if (!customDeck.Consonants) { return false; }

	let categoryKeys = Object.keys(customDeck.Consonants.categories);

	categoryKeys = categoryKeys.map((key) => {
		return key.toLowerCase();
	});

	/*if (word.word === "choose" && customDeck.title === "ALL") {
		debugger;
	}*/

	wordHasAllValues[specialRuleIndex] = categoryKeys.some((el) => (word.special_rule.includes(el)));

	for (let categoryIndex in customDeck.Consonants.categories) {
		let category = customDeck.Consonants.categories[categoryIndex];

		if ((!category) || (!category.field)) {
			continue;
		}

		for (let fieldIndex = 0; fieldIndex < category.field.length; fieldIndex++) {
			let field = category.field[fieldIndex];

			if (category.check_value_against_special_rule) {
				if (!wordHasAllValues[specialRuleIndex]) {
					wordHasAllValues[specialRuleIndex] = category.values.some((el) => (word.special_rule.includes(el)));
				}
			}

			if (category.values.includes(word[`card${field.card_position}`][field.field])) {
				switch (field.card_position) {
					case 1:
						wordHasAllValues[card1Index] = true;
						break;
					case 3:
						wordHasAllValues[card3Index] = true;
						break
					default:
						break;
				}
			}
		}

		if (word.card1.face === "blank") {
			wordHasAllValues[card1Index] = true;
		}

		if (word.card3.face === "blank") {
			wordHasAllValues[card3Index] = true;
		}
	}

	let wordHasAllValuesAcc = !!wordHasAllValues.reduce((acc, cv) => {
		return acc & cv;
	});

	return wordHasAllValuesAcc;
}

export function TestBlending(word) {
	let customDeck = this.deck;

	if (!TestSyllable(word, customDeck)) {
		return false;
	}

	if (!TestConsonant(word, customDeck)) {
		return false;
	}

	return true;
}

/**
 * 
 * @param {Word} word 
 * Test if the custom deck contains any Visual rules and then if the word passes the rules
 */
export function TestVisual(word) {
	let customDeck = this.deck;
	let listIndex = 0;
	let faceIndex = 1;
	let wordHasAllValues = [false, false]; // [0] = list; [1] = face;

	for (let categories in customDeck) {
		for (let categoryIndex in customDeck[categories].categories) {
			let category = customDeck[categories].categories[categoryIndex];

			if ((!category) || ((categories.toLowerCase() !== "visual".toLowerCase()) && (!category.show_in_visual))) {
				continue;
			}

			for (let valueIndex = 0; valueIndex < category.values.length; valueIndex++) {

				if (!category.field) {
					continue;
				}

				for (let fieldIndex = 0; fieldIndex < category.field.length; fieldIndex++) {
					let fieldValue = category.values[valueIndex];
					let cardFieldValue = word.card1.face;

					if ((!word.graphic_only) &&
						(
							(word.special_rule.includes(categoryIndex.toLowerCase())) ||
							(word.syllable_type.includes(categoryIndex.toLowerCase())) ||
							((category.syllable_type) && (word.syllable_type.includes(category.syllable_type.toLowerCase())))
						)
					) {
						wordHasAllValues[listIndex] = true;
					}

					if ((word.graphic_only) && (categoryIndex.toLowerCase() === word.card1.list.toLowerCase()) && (cardFieldValue.toLowerCase() === fieldValue.toLowerCase()) && (!wordHasAllValues[listIndex])) {
						wordHasAllValues[listIndex] = true;
					}

					if ((cardFieldValue !== "") && (cardFieldValue.toLowerCase() === fieldValue.toLowerCase())) {
						wordHasAllValues[faceIndex] = true;
					}
				}
			}
		}
	}

	let wordHasAllValuesAcc = !!wordHasAllValues.reduce((acc, cv) => {
		return acc & cv;
	});

	return wordHasAllValuesAcc;
}

/**
 * 
 * @param {Array} visualArray 
 * Combines all cards into card1 to remove all duplicates since Visual is always a single card
 */
export function MergeVisualCards(visualArray) {
	if(!visualArray){return};
	let tmp = visualArray.map((card) => {
		let cards = [];

		let tmpCard1 = {};
		let tmpCard2 = {};
		let tmpCard3 = {};

		for (let cardKeyIndex in card) {
			if ((cardKeyIndex === "card1") || (cardKeyIndex === "card2") || (cardKeyIndex === "card3")) {
				continue;
			}

			tmpCard1[cardKeyIndex] = card[cardKeyIndex];
			tmpCard1.card1 = {};

			tmpCard2[cardKeyIndex] = card[cardKeyIndex];
			tmpCard2.card1 = {};

			tmpCard3[cardKeyIndex] = card[cardKeyIndex];
			tmpCard3.card1 = {};
		}

		if ((card.card1) && (card.card1.face !== "")) {
			tmpCard1.card1 = card.card1;
			cards.push(tmpCard1);
		}

		if ((card.card2) && (card.card2.face !== "")) {
			tmpCard2.card1 = card.card2;
			cards.push(tmpCard2);
		}

		if ((card.card3) && (card.card3.face !== "")) {
			tmpCard3.card1 = card.card3;
			cards.push(tmpCard3);
		}

		return cards;
	});

	let dedup = RemoveVisualDuplicates(tmp.flat());
	
	return dedup;
}

/**
 * 
 * @param {Array} visualArray 
 * Removes all duplicates
 */
export function RemoveVisualDuplicates(visualArray) {
	let tmpVisual = JSON.parse(JSON.stringify(visualArray));
	let uniqueVisuals = {};

	tmpVisual.forEach((word) => {

		let type = `-${word.card1.cardnumber}`;

		if ((word.graphic_only) && (word.card1.list)) {
			type = `-${word.card1.list}`;
		}

		if ((word.card1) && (word.card1 !== "blank") && (word.card1 !== "")) {
			if (uniqueVisuals[`${word.card1.face}${type}`] === undefined) {
				uniqueVisuals[`${word.card1.face}${type}`] = word;
				let tmpCardType = uniqueVisuals[`${word.card1.face}${type}`].card1.type;
				let tmpRule = uniqueVisuals[`${word.card1.face}${type}`].special_rule;
				let tmpSyllable = uniqueVisuals[`${word.card1.face}${type}`].syllable_type;

				uniqueVisuals[`${word.card1.face}${type}`].card1.type = [];
				uniqueVisuals[`${word.card1.face}${type}`].special_rule = [];
				uniqueVisuals[`${word.card1.face}${type}`].syllable_type = [];

				if (tmpCardType) {
					uniqueVisuals[`${word.card1.face}${type}`].card1.type.push(tmpCardType);
				}

				if (tmpRule) {
					uniqueVisuals[`${word.card1.face}${type}`].special_rule.push(tmpRule);
				}

				if (tmpSyllable) {
					uniqueVisuals[`${word.card1.face}${type}`].syllable_type.push(tmpSyllable);
				}
			} else {
				if (!uniqueVisuals[`${word.card1.face}${type}`].card1.type.includes(word.card1.type)) {
					uniqueVisuals[`${word.card1.face}${type}`].card1.type.push(word.card1.type)
				}

				if (!uniqueVisuals[`${word.card1.face}${type}`].special_rule.includes(word.special_rule)) {
					uniqueVisuals[`${word.card1.face}${type}`].special_rule.push(word.special_rule);
				}

				if (!uniqueVisuals[`${word.card1.face}${type}`].syllable_type.includes(word.syllable_type)) {
					uniqueVisuals[`${word.card1.face}${type}`].syllable_type.push(word.syllable_type);
				}
			}
		}
	});

	let tmpUniques = []
	for (let key in uniqueVisuals) {
		tmpUniques.push(uniqueVisuals[key]);
	}
	return tmpUniques;
}