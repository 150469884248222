import React from "react";
import { /*PopulateWordsDB, SwabTheDecks, */RemoveAllDecks } from "./Globals";
import {SetBackupProvider} from "./SyncSettings/Preferences";

export class Diagnostics extends React.Component {
	render() {
		return (
			<div className="actions bg-white p-2">
				{/*
				<button onClick={PopulateWordsDB.bind(null, true, true)} className="btn mb-2 btn-primary btn-lg btn-block">Reset Words</button>
				<button onClick={SwabTheDecks.bind(null)} className="btn btn-primary btn-lg btn-block">Clean Decks</button>
				*/}
				<button onClick={SetBackupProvider.bind(null)} className="btn btn-primary btn-lg btn-block">Multi Device Cloud Sync Setup</button>
				<hr />
				<button onClick={RemoveAllDecks.bind(null)} className="btn btn-danger btn-lg btn-block">Remove Decks</button>
			</div>
		);
	}
}