import React from "react";
import Swal from "sweetalert2";
import Papa from "papaparse";
import moment from "moment";
import sync from "./SyncSettings/Sync";

import { BackupProviders } from "./SyncSettings/Preferences";

export class Home extends React.Component {
	syncer = new sync();

	constructor(props) {
		super(props);

		this.state = {
			messages: []
		};
	}

	componentDidMount() {
		this.props.isBusy(true);

		let searchParams = new URLSearchParams("?" + window.location.search.substring(1));
		//The user is using dropbox
		if (searchParams.has("code")) {
			BackupProviders["dropbox"].token(searchParams.get("code")).then(() => {
				this.syncer.pull().then(this.syncer.push.bind(this.syncer));
			});

			let url = new URL(window.location);
			url.searchParams.delete("code");
			window.history.pushState({}, "", url);
		}
		let component = this;

		Papa.parse("./assets/csv/Messages.csv", {
			delimiter: ",",
			header: true,
			download: true,
			complete: function (results) {
				let messages = [];
				for (let dataIndex = (results.data.length - 1); dataIndex > -1; dataIndex--) {
					let row = results.data[dataIndex];
					let now = moment().startOf("day");
					let start = moment(row.StartDate, "M/D/YYYY");
					let end = moment(row.EndDate, "M/D/YYYY");

					if ((now.diff(start, "days") > -1) && (end.diff(now, "days") > -1)) {
						messages.push(
							<div className={`alert alert-dismissible fade show alert-${row.Type}`} role="alert" key={dataIndex} dangerouslySetInnerHTML={{ __html: row.Message }} />
						);
					}
				}

				component.setState(() => ({ messages: messages }));
			}
		});
		this.props.isBusy(false);
	}

	render() {

		return (
			<React.Fragment>
				<div>
					<h1 className="display-4 mb-5 text-center text-white">M.A. Rooney Foundation Online Tools</h1>
				</div>

				<div className="row">
					<div className="col-sm-3">
						<div className="text-center display-5 mb-1 text-white">Visual/Blending
							<a href='/#/saveloaddecks'><img className="main-img border border-dark shadow-lg rounded h-100 d-block m-auto" alt="Build Visual and/or Blending Drill" title="Build Visual and/or Visual Drills" src="./assets/cards/1.52.png" /></a>
						</div>
					</div>
					<div className="col-sm-6">
						<div className="messages mt-2">{this.state.messages}</div>
					</div>
					<div className="col-sm-3">
						<div className="text-center display-5 mb-1 text-white">Handwriting
							<a href='/#/handwritingdrill'><img className="main-img border border-dark shadow-lg rounded h-100 d-block m-auto center-img" alt="Handwriting Drill" title="Online Handwriting" src="./assets/cards/hwla.svg" /></a>
						</div>
					</div>
				</div>


			</React.Fragment>

		);
	}
}