import React from "react";
import localforage from "localforage";
import { saveAs } from "file-saver";
import moment from "moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { TestBlending, TestVisual, MergeVisualCards } from "./CardFilters";
import { dataKeys, MergeBaseRules, mergeDecks } from "./Globals";
//import { BackupProviders, getBackupProvider, RunBackup } from "./SyncSettings/Preferences";
import sync from "./SyncSettings/Sync";
import { data, merge } from "jquery";
import { TailSpin } from "react-loader-spinner";


export class RecycleBin extends React.Component {
	syncer = new sync();
	constructor(props) {
		super(props);

		this.state = {
			decks: [],
			words: [],
			visualwords: [],
			selectedDeck: "",
			rules: {},
			decksLoaded: false
		};
	}

	componentDidMount() {
		this.syncer.pull().then(this.loadAfterSync.bind(this));
		let url = new URL(window.location);
			url.searchParams.delete("code");
			window.history.pushState({}, "", url);

	}

	loadAfterSync() {
		
		localforage.getItem(dataKeys.WORDS).then((words) => {
			this.setState({
				words: words,
				visualwords: MergeVisualCards(words)
			});
			localforage.getItem(dataKeys.CURRENTRULESET).then((currentRuleSet) => {
				if (currentRuleSet === null) {
					this.setState({ selectedDeck: "**" + (new Date()).getTime() + "**" }); //Hopefully this is always unique
				} else {
					this.setState({ selectedDeck: currentRuleSet.title });
				}

				this.loadSavedDecks();
			});
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	filterData() {

	}

	loadSavedDecks() {
		localforage.getItem(dataKeys.DECKS)
			.then((decks) => {
				if (!decks) {
					this.setState({
						decks: [],
						decksLoaded: true
					});
					return;
				}

				let mergedDecks = decks.map(MergeBaseRules)/*.filter(fa => fa.deleted === 1)*/;

				let sortedDecks = mergedDecks.sort((deckA, deckB) => {
					if (deckA.title.toUpperCase() < deckB.title.toUpperCase()) { return -1; }
					if (deckA.title.toUpperCase() > deckB.title.toUpperCase()) { return 1; }
					return 0;
				});

				let counted = sortedDecks.map(this.countCards, this);
				
				this.setState({
					decks: counted,
					decksLoaded: true
				});
			});
	}

	countCards(deck) {
		let filteredConsonants = [];
		let filteredVisual = [];
		
		if(typeof deck.lessonDeck!=="undefined" && deck.lessonDeck){return deck}

		deck.visualCount = 0;
		deck.blendingCount = 0;

		if (this.state.words.length !== 0) {
			filteredConsonants = this.state.words.filter(TestBlending, { deck: deck });

			deck.blendingCount = filteredConsonants.length;

			filteredVisual = this.state.visualwords.filter(TestVisual, { deck: deck });

			deck.visualCount = filteredVisual.length;
		}

		return (
			deck
		);
	}

	async deleteDeck(deck) {
		let tmpDecks = await localforage.getItem(dataKeys.DECKS)
		
		let { value: continueDelete } = await Swal.fire({
			title: `Are you sure you want to delete ${deck.title}?`,
			//need to change note:  if we mark deck as gone
			html: "This action will permanently remove your deck",
			showCancelButton: true,
			confirmButtonText: "Yes"
		});

		if (continueDelete) {

			for (let deckIndex = 0; deckIndex < tmpDecks.length; deckIndex++) {
				let currentDeck = tmpDecks[deckIndex];

				if (currentDeck.title.toLowerCase() === deck.title.toLowerCase()) {
					deck = currentDeck;
					tmpDecks[deckIndex].deleted = -1 //perm deleted

					for (let key in tmpDecks[deckIndex]) {
						switch (key) {
							case "modified":
								tmpDecks[deckIndex].modified = new Date().getTime()
								break
							case "title":
							case "normalized_name":
							case "created":
							case "deleted":
								continue;
							default:
								delete tmpDecks[deckIndex][key];
						}
					}
					break;
				}
			}

			localforage.setItem(dataKeys.DECKS, this.state.decks).then(() => {

				Swal.fire({
					title: "Deleted",
					text: deck.title + " has been deleted",
					icon: "success"
				});
				localforage.setItem(dataKeys.DECKS, tmpDecks);
				this.syncer.push();
				window.location = '/#/saveloaddecks';
			});
		}
	}

	async restoreDeck(deck) {

		let tmpDecks = await localforage.getItem(dataKeys.DECKS)

		for (let deckIndex = 0; deckIndex < tmpDecks.length; deckIndex++) {
			let currentDeck = tmpDecks[deckIndex];

			if (currentDeck.title.toLowerCase() === deck.title.toLowerCase()) {
				deck = currentDeck;
				tmpDecks[deckIndex].deleted = 0;
				tmpDecks[deckIndex].modified = new Date().getTime();
				break;
			}
		}


		Swal.fire({
			title: "Restored",
			text: deck.title + " has been restored",
			icon: "success"
		}).then((result) => {
			localforage.setItem(dataKeys.DECKS, tmpDecks);
			this.syncer.push();
			window.location = '/#/saveloaddecks';
		})
	}


	async emptyRecycleBin() {
		let tmpDecks = await localforage.getItem(dataKeys.DECKS)
		for (var i = 0; i < tmpDecks.length; i++) {
			if (tmpDecks[i].deleted === 1) {
				tmpDecks[i].deleted = -1 //perm deleted

				for (let key in tmpDecks[i]) {
					switch (key) {
						case "modified":
							tmpDecks[i].modified = new Date().getTime()
							break
						case "title":
						case "normalized_name":
						case "created":
						case "deleted":
							continue;
						default:
							delete tmpDecks[i][key];
					}
				}
			}
		}

		Swal.fire({
			title: "Recycle Bin Emptied",
			text: "Your Recycle Bin has been Emptied",
			icon: "success"
		}).then((result) => {

			localforage.setItem(dataKeys.DECKS, tmpDecks)
			this.syncer.push();
			window.location = '/#/saveloaddecks';	

		})
	}

	loadSelectedRow() {
		for (let deckIndex = 0; deckIndex < this.state.decks.length; deckIndex++) {
			let deck = this.state.decks[deckIndex];

			if (deck.title.toLowerCase() === this.state.selectedDeck.toLowerCase()) {
				localforage.removeItem(dataKeys.CURRENTRULESET).then((rs) => {
					localforage.setItem(dataKeys.CURRENTRULESET, deck).then((ruleSet) => {
						Swal.fire({
							title: "Added",
							text: `${deck.title} has been set as the current deck`,
							icon: "success"
						});
					});
				});
				break;
			}
		}
	}

	showLoadModal(deck) {
		return (
			<div className="modal" id="loadcomplete" tabindex="-1" role="dialog">
				<div className="modal-dialog modal-sm" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Deck Loaded</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>{deck.title}</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	download(deck) {
		localforage.getItem(dataKeys.DECKS).then((decks) => {
			let title = "AllDecks";

			if (Array.isArray(deck)) {
				decks.map((deck) => {

					let pageDeck = this.state.decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));

					if (pageDeck.length === 0) {
						deck.blendingCount = -1;
						deck.visualCount = -1;
						return deck;
					}

					deck.blendingCount = pageDeck[0].blendingCount;
					deck.visualCount = pageDeck[0].visualCount;


					return deck;
				});

				let downloadText = JSON.stringify(decks, null, "\t");

				let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });
				saveAs(blob, `${title}.json`);

				return;
			}

			let foundDeck = [];

			foundDeck = decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));
			let pageDeck = this.state.decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));

			if (foundDeck.length === 1) {
				title = foundDeck[0].title ? foundDeck[0].title : "MARooneyCardDeck";

				if (pageDeck.length === 0) {
					deck.blendingCount = -1;
					deck.visualCount = -1;
					return deck;
				}

				foundDeck[0].blendingCount = pageDeck[0].blendingCount;
				foundDeck[0].visualCount = pageDeck[0].visualCount;

				//deck = [deck];
			}

			let downloadText = JSON.stringify(foundDeck, null, "\t");

			let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });
			saveAs(blob, `${title}.json`);
		});
	}


	render() {
		let listItems = [];
		if ((this.state.decks.length === 0) && (!this.state.decksLoaded)) {
			
			<TailSpin visible={true} wrapperClass="d-flex justify-content-center pt-2"></TailSpin>

		} else if ((this.state.decks.filter(fa => fa.deleted === 1)).length === 0 && (this.state.decksLoaded)) {
			listItems.push(
				<div key="no-decks" className="d-flex justify-content-center pt-2">
					<p>No Decks have been deleted.<Link to="/SaveLoadDecks">Return to Build Deck</Link></p>
				</div>
			);
				<TailSpin visible={true} wrapperClass="d-flex justify-content-center pt-2"></TailSpin>

		} else {
			listItems = this.state.decks.map((deck, index) => {
				if (typeof deck.deleted === 'undefined' || deck.deleted === 0||deck.deleted===-1) {

					return null
				}

				return (
					<div key={deck.title.toLowerCase()} className={`row align-items-center pb-2 filterable`} data-title={deck.title}>
						<div className="col text-center text-truncate">{deck.title}</div>
						<div className="col text-center">{deck.blendingCount}</div>
						<div className="col text-center">{deck.visualCount}</div>
						<div className="col text-center">{deck.notes}</div>
						<div className="col text-center">{moment(deck.created).format("MMM Do YY")}</div>
						<div className="col text-center">
							<div className="btn-group">
								<button type="button" className="dropdown-toggle btn navbar-light" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span className="bi-list"></span>
								</button>
								<div className="dropdown-menu">
									<button className="dropdown-item text-primary" onClick={this.restoreDeck.bind(this, deck)}>Restore</button>
									<button className="dropdown-item text-danger" onClick={this.deleteDeck.bind(this, deck)}>Permanently Delete</button>
								</div>
							</div>
						</div>
					</div>
				);
			}, this);
		}

		return (
			<React.Fragment>
				<div id="deck-table" className="p-2">
					<h1>Recycle Bin</h1>
					<div className="row">
						<div className="col text-center">Name</div>
						<div className="col text-center">Blending Count</div>
						<div className="col text-center">Visual Count</div>
						<div className="col text-center">Notes</div>
						<div className="col text-center">Created</div>
						<div className="col text-center">Actions</div>
					</div>

					<div className="flex-fill" id="deck-items">
						{listItems}
					</div>
					<div className="bg-light p-4 rounded">
						<button className="btn btn-danger m-2 float-right" onClick={this.emptyRecycleBin.bind(this, this.state.decks)}>Empty Recycle Bin</button>
						<Link to="/SaveLoaddecks" className="btn btn-secondary m-2 float-right">My Decks</Link>
						<input className="invisible"></input>
					</div>
				</div>
			</React.Fragment>
		);
	}
}