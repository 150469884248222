import React from "react";
import { Link } from "react-router-dom";
import {RemoveAllDecks} from "./Globals";
import {DeleteBackupProvider,SetBackupProvider} from "./SyncSettings/Preferences";
/**
 * Rename Save/Load Decks to Your Decks
 */

export class NavMenu extends React.Component {
	render() {
		return (
			<div className="navigation flex-column navbar-light">
				<button className="nav-link sticky-top dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span className="bi-list"></span>
				</button>
				<div className="dropdown-menu p-2">
					<nav className="nav flex-column">
						<Link to="/SaveLoadDecks" className="btn btn-outline-secondary mb-1">My Decks</Link>
						<Link to="/DeckConfiguration" className="btn btn-outline-secondary mb-1">Build Deck</Link>
						<Link to="/HandwritingDrill" className="btn btn-outline-secondary mb-1">Handwriting App</Link>
						<button onClick={SetBackupProvider.bind(null)} className="btn btn-outline-secondary mb-1">Setup Sync Provider</button>
						<button onClick={DeleteBackupProvider.bind(null)} className="btn btn-outline-secondary mb-1">Delete Sync Provider</button>
						<Link to="/" className="btn btn-outline-secondary mb-1">Home</Link>
						<button onClick={RemoveAllDecks.bind(null)} className="btn mb-1 btn-danger">Remove All Decks</button>
					</nav>
				</div>
			</div>
		);
	}
}