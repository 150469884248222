import React from "react";
import localforage from "localforage";
import { saveAs} from "file-saver";
import moment from "moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { TestBlending, TestVisual, MergeVisualCards } from "./CardFilters";
import { dataKeys, MergeBaseRules, addFontVariable, mergeDecks } from "./Globals";
import sync from "./SyncSettings/Sync";
import { toProperCase, SetBackupProvider, BackupProviders } from "./SyncSettings/Preferences";
import { TailSpin } from "react-loader-spinner";



export class SaveLoadDecks extends React.Component {
	syncer = new sync();

	constructor(props) {
		super(props);

		this.state = {
			decks: [],
			words: [],
			visualwords: [],
			selectedDeck: "",
			rules: {},
			decksLoaded: false,
			backupprovider: " ",
			backupDateTime: "",
			isBusy: false,
			msync: "Setup Sync Provider",
			email: ""
		};

	}

	componentDidMount() {

		this.syncer.pull().then(() => {
			this.loadAfterSync()
		});

		let url = new URL(window.location);
		url.searchParams.delete("code");
		window.history.pushState({}, "", url);


	}

	async loadAfterSync() {

		let backupprovider = await localforage.getItem(dataKeys.BACKUPPROVIDER)

		if (backupprovider) {

			///first run
			let backupDateTime = await localforage.getItem(dataKeys.BACKUPDATETIME)
			let email = await localforage.getItem(dataKeys.USEREMAIL)
			this.setState({
				msync: 'Manual Sync',
				backupprovider: backupprovider,
				backupDateTime: backupDateTime,
				email: email !== null ? " (" + email + ") " : " "
			})

			BackupProviders[backupprovider].addEventListener("backup", (async function (metadata) {
				console.log(metadata)
				let backupDateTime = await localforage.getItem(dataKeys.BACKUPDATETIME)
				let email = await localforage.getItem(dataKeys.USEREMAIL)
				console.log(backupDateTime)
				this.setState({
					backupDateTime: backupDateTime ? " Decks Last Modified On " + backupDateTime : ' will update after initial deck created',
					email: email !== null ? " (" + email + ") " : " "

				});
			}).bind(this))

		} else {
			this.setState({
				backupprovider: "Not Set",
				email: " "
			});
		}


		let words = await localforage.getItem(dataKeys.WORDS);

		this.setState({
			words: words,
			visualwords: MergeVisualCards(words)
		});

		await this.loadSavedDecks();

	}

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	}

	filterData() {

	}

	async addPulledDecks(decks) {
		await this.setState({ decks: decks });
	}

	async loadSavedDecks() {

		await localforage.getItem(dataKeys.DECKS)
			.then((decks) => {
				if (!decks || typeof decks.length === 'undefined' || decks.filter(fa => fa.deleted !== -1 || fa.deleted !== 1).length === 0) {
					this.setState({
						decks: [],
						decksLoaded: true,
					});
					return;
				}

				let mergedDecks = decks.map(MergeBaseRules)/*.filter(fa => fa.deleted !== -1 && fa.deleted !== 1)*/;
				let sortedDecks = [];

				if (mergedDecks.length > 0) {
					sortedDecks = mergedDecks.sort((deckA, deckB) => {
						if (deckA.title.toUpperCase() < deckB.title.toUpperCase()) { return -1; }
						if (deckA.title.toUpperCase() > deckB.title.toUpperCase()) { return 1; }
						return 0;
					});
				}

				let counted = sortedDecks.map(this.countCards, this);

				this.setState({
					decks: counted,
					decksLoaded: true
				});
			})


	}

	async manualSync() {


		let backupprovider = await localforage.getItem(dataKeys.BACKUPPROVIDER)

		if (backupprovider) {
			let localTime = new Date();
			localforage.setItem(dataKeys.LASTFILEBACKUP, localTime.getTime());
			let syncer = new sync()
			await syncer.push();
			window.location.reload()
		} else {
			await SetBackupProvider()
		}

	}

	countCards(deck) {
		let filteredConsonants = [];
		let filteredVisual = [];

		deck.visualCount = 0;
		deck.blendingCount = 0;
		if(typeof deck.lessonDeck!=="undefined" && deck.lessonDeck){return deck}
		if (this.state.words.length !== 0) {
			filteredConsonants = this.state.words.filter(TestBlending, { deck: deck });

			deck.blendingCount = filteredConsonants.length;

			filteredVisual = this.state.visualwords.filter(TestVisual, { deck: deck });
			deck.visualCount = filteredVisual.length;
		}

		return (
			deck
		);
	}

	async modifyFont(deck) {
		let tmpDecks = this.state.decks;

		for (let deckIndex = 0; deckIndex < tmpDecks.length; deckIndex++) {
			let currentDeck = tmpDecks[deckIndex];

			if (currentDeck.title.toLowerCase() === deck.title.toLowerCase()) {
				tmpDecks[deckIndex].basicFont = !tmpDecks[deckIndex].basicFont;
				tmpDecks[deckIndex].modified = new Date().getTime();
				this.setState({
					decks: tmpDecks
				});

				await localforage.setItem(dataKeys.DECKS, this.state.decks)
				this.setState({
					isBusy: true
				});

				await this.syncer.push()

				this.setState({
					isBusy: false
				});

				break;
			}

		}
	}

	async deleteDeck(deck) {

		let tmpDecks = this.state.decks;


		let { value: continueDelete } = await Swal.fire({
			title: `Are you sure you want to delete ${deck.title}?`,
			//need to change note:  if we mark deck as gone
			html: "This action will move this deck to your recycle bin",
			showCancelButton: true,
			confirmButtonText: "Yes"
		});

		if (continueDelete) {

			for (let deckIndex = 0; deckIndex < tmpDecks.length; deckIndex++) {
				let currentDeck = tmpDecks[deckIndex];

				if (currentDeck.title.toLowerCase() === deck.title.toLowerCase()) {
					deck = currentDeck;
					tmpDecks[deckIndex].deleted = 1;
					tmpDecks[deckIndex].modified = new Date().getTime();
					//tmpDecks.splice(deckIndex, 1);
					break;
				}
			}

			this.setState({
				decks: tmpDecks
			});


			localforage.setItem(dataKeys.DECKS, this.state.decks).then(() => {
				Swal.fire({
					title: "Deleted",
					text: deck.title + " has been deleted",
					icon: "success"
				});

				this.syncer.push();
			});
		}
	}

	loadSelectedRow() {
		for (let deckIndex = 0; deckIndex < this.state.decks.length; deckIndex++) {
			let deck = this.state.decks[deckIndex];

			if (deck.title.toLowerCase() === this.state.selectedDeck.toLowerCase()) {
				localforage.removeItem(dataKeys.CURRENTRULESET).then((rs) => {
					localforage.setItem(dataKeys.CURRENTRULESET, deck).then((ruleSet) => {
						Swal.fire({
							title: "Added",
							text: `${deck.title} has been set as the current deck`,
							icon: "success"
						});
					});
				});
				break;
			}
		}
	}

	showLoadModal(deck) {
		return (
			<div className="modal" id="loadcomplete" tabindex="-1" role="dialog">
				<div className="modal-dialog modal-sm" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Deck Loaded</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>{deck.title}</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	download(deck) {
		localforage.getItem(dataKeys.DECKS).then((decks) => {
			decks = decks.filter((decks) =>(decks.lessonDeck!==true))
			let title = "AllDecks";

			if (Array.isArray(deck)) {
				
				decks.map((deck) => {

					let pageDeck = this.state.decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));

					if (pageDeck.length === 0) {
						deck.blendingCount = -1;
						deck.visualCount = -1;
						return deck;
					}

					deck.blendingCount = pageDeck[0].blendingCount;
					deck.visualCount = pageDeck[0].visualCount;


					return deck;
				});

				let downloadText = JSON.stringify(decks, null, "\t");

				let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });
				saveAs(blob, `${title}.json`);

				return;
			}

			let foundDeck = [];

			foundDeck = decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));
			let pageDeck = this.state.decks.filter((xDeck) => (deck.title.toLowerCase() === xDeck.title.toLowerCase()));

			if (foundDeck.length === 1) {
				title = foundDeck[0].title ? foundDeck[0].title : "MARooneyCardDeck";

				if (pageDeck.length === 0) {
					deck.blendingCount = -1;
					deck.visualCount = -1;
					return deck;
				}

				foundDeck[0].blendingCount = pageDeck[0].blendingCount;
				foundDeck[0].visualCount = pageDeck[0].visualCount;

				//deck = [deck];
			}

			let downloadText = JSON.stringify(foundDeck, null, "\t");

			let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });
			saveAs(blob, `${title}.json`);
		});
	}

	downloadwords(deck) {
		let filteredConsonants = [];

		filteredConsonants = this.state.words.filter(TestBlending, { deck: deck });

		filteredConsonants = filteredConsonants.map((word) => { return `${word.card1.name}\t${word.card2.name}\t${word.card3.name}\t${word.word}`; });

		let title = deck.title;

		let downloadText = filteredConsonants.join("\n");

		let blob = new Blob([downloadText], { type: "text/plain;charset=utf-8" });
		saveAs(blob, `${title}-WordList.txt`);

	}

	uploadFileClick(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		document.getElementById("fileElem").click();
	}

	uploadFileFromDialog(evt) {
		this.addFiles(evt.currentTarget.files);
	}

	uploadFile(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		let dataTransfer = evt.dataTransfer;
		let files = dataTransfer.files;

		this.addFiles(files);

	}

	addFiles(files) {

		let component = this;

		([...files]).forEach((file) => {
			let reader = new FileReader();
			reader.readAsText(file);

			reader.onloadend = function () {
				let jsonDeck = JSON.parse(reader.result);

				jsonDeck = jsonDeck.filter((deck) => {
					//temp program to add basic font variable
					addFontVariable(deck);
					deck.modified = new Date().getTime();
					deck.created = new Date().getTime();
					return ((deck.Syllable) || (deck.Consonants) || (deck.Visual) || (deck.deleted === -1));
				});

				if (jsonDeck.length === 0) {
					Swal.fire({
						title: "Bad Deck File",
						text: `No new configurations loaded. Perhaps the deck file is corrupted?`,
						icon: "error"
					});

					return;
				}

				localforage.getItem(dataKeys.DECKS).then((decks) => {
					if ((!decks) || (decks.length === 0)) {
						localforage.setItem(dataKeys.DECKS, jsonDeck).then((xDecks) => {
							Swal.fire({
								title: "Added",
								text: `New configurations have been added`,
								icon: "success"
							});

							component.setState({
								decks: xDecks
							});
						});

						let subsyncer = new sync()

						component.setState({
							isBusy: true
						});

						subsyncer.push()

						component.setState({
							isBusy: false
						});


						return;
					}
					let deckAdded = false;
					let newDecks = mergeDecks(decks, jsonDeck)

					if ((newDecks.length === decks.length) && (!deckAdded)) {
						Swal.fire({
							title: "Finished",
							text: `No new decks were added`,
							icon: "warning"
						});
						return;
					}

					localforage.setItem(dataKeys.DECKS, newDecks).then((xDecks) => {

						component.setState({
							decks: xDecks
						});


						Swal.fire({
							title: "Finished",
							text: `New configurations have been added`,
							icon: "success"
						}).then((result) => {

							component.setState({
								isBusy: true
							})

							let subsyncer = new sync()
							subsyncer.push()

							component.setState({
								isBusy: false
							})
						}

						);


					});
				});
			}
		});

	}

	async loadLessonPlans() {

		await this.deleteOldLessonDecks()
		
		var files = ["Cleaned Kindergarten Lesson Decks.json","Cleaned 1st Grade Lesson Decks.json","Cleaned 2nd Grade Pre Lesson Decks.json","Cleaned 2nd Grade Lesson Decks.json","Cleaned 3rd Grade Lesson Decks.json"];
		
		
		let lDecksNew = []
	
		for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
			let lDecksFile = await fetch("./assets/LessonDecks/" + files[fileIndex])
			
			let lDecks = await lDecksFile.json()
			
			for (let deckIndex = 0; deckIndex < lDecks.length; deckIndex++) {

				lDecks[deckIndex].lessonDeck = true
				lDecks[deckIndex].normalized_name = lDecks[deckIndex].normalized_name + "_MARF20230301"

				if (lDecks[deckIndex].deleted === 0 || typeof lDecks[deckIndex].deleted === 'undefined') {
					lDecksNew.push(lDecks[deckIndex])
				}
			}
		}
		

		if (await localforage.getItem(dataKeys.DECKS) === null) {

			await localforage.setItem(dataKeys.DECKS, lDecksNew)

		} else {
			let userDecks = await localforage.getItem(dataKeys.DECKS)
			await localforage.setItem(dataKeys.DECKS, mergeDecks(userDecks, lDecksNew))
		}


	};

	async deleteOldLessonDecks() {
		let userDecks = await localforage.getItem(dataKeys.DECKS)
		
		if (!userDecks) { return }
		
		for (let deckIndex = userDecks.length-1; deckIndex >= 0; deckIndex--) {
			
			if (userDecks[deckIndex].lessonDeck) {
				userDecks.splice(deckIndex, 1)
			}
		}
		
		await localforage.setItem(dataKeys.DECKS, userDecks)

	}


	updateDragClass(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		switch (evt.type) {
			case "dragenter":
			case "dragover":
				evt.target.classList.add("dragging");
				break;
			case "dragleave":
				evt.target.classList.remove("dragging");
				break;
			default:
				break;
		}
	}

	render() {
		
		let listItems = [];

		if ((this.state.decks.length === 0 && (!this.state.decksLoaded))) {
			listItems.push(
				/*<div key="spinner" className="d-flex justify-content-center pt-2">
					<div className="spinner-border" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>*/

				<TailSpin key="spinner" visible={true} wrapperClass="d-flex justify-content-center pt-2"></TailSpin>
				
			);

		} else if ((this.state.decks.length - this.state.decks.filter(fa => fa.deleted === 1 || fa.deleted === -1 || fa.lessonDeck === true).length) === 0 && (this.state.decksLoaded)) {
			listItems.push(
				<div key="no-decks" className="d-flex justify-content-center pt-2">
					<p>You do not currently have any decks. Create a deck <Link to="/DeckConfiguration">now</Link></p>
				</div>
			);
		} else {
			listItems = this.state.decks.map((deck, index) => {

				if (typeof deck.deleted !== 'undefined' && deck.deleted !== 0) { return null }
				if (deck.lessonDeck === true) { return null }
				return (
					<div key={deck.title.toLowerCase()} className={`row align-items-center pb-2 filterable`} data-title={deck.title}>
						<div className="col text-center text-truncate">{deck.title}</div>
						<div className="col text-center">
							<div className="form-check form-check-inline">
								<input type="checkbox" className="form-check-input" id="" checked={deck.basicFont} readOnly />
								<label className="form-check-label" htmlFor="fontcheck" onClick={this.modifyFont.bind(this, deck)}></label>
							</div>
						</div>
						<div className="col text-center">{deck.blendingCount}</div>
						<div className="col text-center">{deck.visualCount}</div>
						<div className="col text-center">{deck.notes}</div>
						<div className="col text-center">{moment(deck.created).format("MMM Do YY")}</div>
						<div className="col text-center">
							<div className="btn-group">
								<button type="button" className="dropdown-toggle btn navbar-light" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span className="bi-list"></span>
								</button>
								<div className="dropdown-menu">
									<Link className={`dropdown-item btn ${(deck.visualCount === 0 ? "disabled btn-danger" : "btn-primary")}`} to={`/VisualDrill/${deck.normalized_name}`}>Visual</Link>
									<Link className={`dropdown-item btn ${(deck.blendingCount === 0 ? "disabled btn-danger" : "btn-primary")}`} to={`/BlendingDrill/${deck.normalized_name}`}>Blending</Link>
									<Link className="dropdown-item btn btn-primary" to={`/DeckConfiguration/${deck.normalized_name}`}>Edit Deck</Link>
									<button className="dropdown-item btn btn-link" onClick={this.download.bind(this, deck)}>Save Deck</button>
									<button className={`dropdown-item btn btn-link ${((!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? "" : "d-none")}`} onClick={this.downloadwords.bind(this, deck)}>Save Word List</button>
									<div className="dropdown-divider"></div>
									<button className="dropdown-item text-danger" onClick={this.deleteDeck.bind(this, deck)}>Delete Deck</button>
								</div>
							</div>
						</div>
					</div>
				);
			}, this);
		}

		return (

			<React.Fragment>
				<div id="deck-table" className="p-2">
					<h1>My Decks</h1>
					<div className="row">
						<div className="col text-center">Name</div>
						<div className="col text-center">Elementary Font</div>
						<div className="col text-center">Blending Count</div>
						<div className="col text-center">Visual Count</div>
						<div className="col text-center">Notes</div>
						<div className="col text-center">Created</div>
						<div className="col text-center">Actions</div>
					</div>
					<div className="flex-fill" id="deck-items">
						{listItems}
					</div>
					<div className="bg-light p-4 rounded">
						<div className="col text-left">{'Sync Provider: ' + toProperCase(this.state.backupprovider) + this.state.email + this.state.backupDateTime}</div>
						<Link to="/RecycleBin" className="btn btn-secondary m-2 float-right">Recycle Bin</Link>
						<button className="btn btn-secondary m-2 float-right" onClick={this.download.bind(this, this.state.decks)}>Save All</button>
						<button className="btn btn-secondary m-2 float-right" onClick={this.manualSync.bind(this)}>{this.state.msync}</button>
						<button className="btn btn-secondary m-2 float-right" onClick={this.uploadFileClick.bind(this)} onDragEnter={this.updateDragClass} onDragOver={this.updateDragClass} onDragLeave={this.updateDragClass} onDrop={this.uploadFile.bind(this)}>Install Deck</button>
						<input onChange={this.uploadFileFromDialog.bind(this)} type="file" id="fileElem" multiple accept="application/json" className="invisible"></input>
					</div>
				</div>
			</React.Fragment>
		);
	}
}