import { dataKeys /*, MergeBaseRules*/ } from "../Globals";
import localforage from "localforage";
import Google from "./Google";
import Dropbox from "./Dropbox";
import Swal from "sweetalert2";
import moment from "moment";
import { localData } from "moment";
import sync from "./Sync";

//run local data from console temp line
window.localData = localData;


//collect data from local browser
export async function localForageData() {

	let data = {
		backups: [],//todo after we get backup files set up
		currentBackupCount: 0, //incement as we store it
		currentDecks: await localforage.getItem(dataKeys.DECKS),
		appSettings: {
			MaxBackupCount: 7
		}
	}
	return data
}

export async function runSync(cloud, cb) {
	localforage.setItem(dataKeys.DECKS, cloud).then((data) => {
		if (cb) {
			cb()
		}
	});
}

export const BackupProviders = {
	"dropbox": new Dropbox(),
	"google": new Google()
};

//RNH Programed 20210514:  Function give indexed backup file name to backup providers
export async function FileNameBackup() {
	let backupIndex = await localforage.getItem(dataKeys.BACKUPINDEX);

	if (!backupIndex) { backupIndex = 0 }
	backupIndex = backupIndex + 1 > 7 ? 1 : backupIndex + 1

	//want to add currentdate to backupindex but need to create and object aka ask Jonathan

	await localforage.setItem(dataKeys.BACKUPINDEX, backupIndex)
	return `DeckBackup${backupIndex}.json`
}


export function CheckLastBackup() {

	localforage.getItem(dataKeys.LASTFILEBACKUP)
		.then((backupDate) => {
			if (!backupDate) {
				return;
			}

			backupDate = moment(backupDate);
			let currentDate = moment();

			if (currentDate.diff(backupDate, "days") > 0) {
				let syncer = new sync();
				syncer.push()
			}
		});
}

/*export async function getBackupProvider() {
	
	let provider = await localforage.getItem(dataKeys.BACKUPPROVIDER);

	if (provider) {
		return provider;
	};
}*/

/*export function RunBackup(hideAlert) {

	getBackupProvider().then((provider) => {
		if (!provider) {
			return;
		}
		BackupProviders[provider].backup(hideAlert);
	});
}*/

export async function SetBackupProvider() {
	let existingProvider = null;
	existingProvider = await localforage.getItem(dataKeys.BACKUPPROVIDER)

	if (existingProvider !== null) {
		await Swal.fire({
			title: "Are you sure?",
			text: "This will remove your current sync provider: " + toProperCase(existingProvider),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes"
		}).then((result) => {
			if (result.value) {
				removeSyncVariables();
				existingProvider = null
			}
		})

	}

	if (existingProvider !== null) { return; }

	let providers = {};

	for (let key in BackupProviders) {

		providers[key] = BackupProviders[key].name;

	}

	//delete providers.google //Temporary until google is fixed


	Swal.fire({
		"title": "Select Sync Provider",
		"input": "select",
		"inputOptions": providers,
		"inputPlaceholder": "Providers...",
		showCancelButton: true,
	})
		.then((value) => {
			if ((!value.isDismissed) && (value.value !== "")) {
				localforage.setItem(dataKeys.BACKUPPROVIDER, value.value)
					.then((provider) => {
						Swal.fire({
							"title": BackupProviders[provider].instructions,
							"text": BackupProviders[provider].subtext,
							"showCancelButton": true,
							"allowEscapeKey": false
						}).then((value) => {
							if (!value.isDismissed) {
								
								BackupProviders[provider].setup().then(
									BackupProviders[provider].auth.bind(BackupProviders[provider]))

								
							} else { DeleteBackupProvider() }
						});
					});
			}
		});

}

//RNH Programed 20210519:  Function allowes user to remove a set backup provider
export async function DeleteBackupProvider() {
	let deleteProvider = await localforage.getItem(dataKeys.BACKUPPROVIDER);
	if (!deleteProvider) {
		await Swal.fire({
			"title": "Sync Provider",
			"text": "No Sync Provider Set",
			"showCancelButton": false,
			"allowEscapeKey": false
		})
		return;
	}

	Swal.fire({
		"title": "Delete Sync Provider",
		"text": "This deletes " + toProperCase(deleteProvider) + " as your sync provider.",
		"showCancelButton": true,
		"allowEscapeKey": false
	}).then(async (value) => {
		if (!value.isDismissed) {
			await removeSyncVariables()
		}
		window.location.reload();
	});

}


export async function removeSyncVariables() {

 	if(await localforage.getItem(dataKeys.BACKUPPROVIDER)==='google'){
		
		await BackupProviders['google'].revokeAccess()
		
	}

	if (localforage.getItem(dataKeys.REFRESHTIME)) { await localforage.removeItem(dataKeys.REFRESHTIME) };
	if (localforage.getItem(dataKeys.LASTREFRESHTIME)) { await localforage.removeItem(dataKeys.LASTREFRESHTIME) };
	if (localforage.getItem(dataKeys.LASTFILEBACKUP)) { await localforage.removeItem(dataKeys.LASTFILEBACKUP) };
	if (localforage.getItem(dataKeys.BACKUPAUTHTOKEN)) { await localforage.removeItem(dataKeys.BACKUPAUTHTOKEN) };
	if (localforage.getItem(dataKeys.REFRESHTOKEN)) { await localforage.removeItem(dataKeys.REFRESHTOKEN) };
	if (localforage.getItem(dataKeys.BACKUPPROVIDER)) { await localforage.removeItem(dataKeys.BACKUPPROVIDER) };
	if (localforage.getItem(dataKeys.BACKUPDATETIME)) { await localforage.removeItem(dataKeys.BACKUPDATETIME) };
	if (localforage.getItem(dataKeys.GOOGLEBACKUPID)) {await localforage.removeItem(dataKeys.GOOGLEBACKUPID) };
	if (localforage.getItem(dataKeys.USEREMAIL)) { await localforage.removeItem(dataKeys.USEREMAIL) };
	if (localforage.getItem("codeVerifier")) { await localforage.removeItem("codeVerifier") };
}

// proper case function (JScript 5.5+)
export function toProperCase(s) {
	if (!s) { return }
	return s.toLowerCase().replace(/^(.)|\s(.)/g,
		function ($1) { return $1.toUpperCase(); });
}


//export default RunBackup;