import React from "react";
import localforage from "localforage";
import { v5 as uuidv5 } from "uuid";
import Swal from "sweetalert2";

import { dataKeys, NormalizeDeckName } from "./Globals";
import CardDeckRules from "./Rules";
//import { BackupProviders, getBackupProvider, RunBackup } from "./SyncSettings/Preferences";
import sync from "./SyncSettings/Sync";
import { useParams } from "react-router-dom";

//const {editName}=useParams();

export class DeckConfiguration extends React.Component {
	syncer = new sync();
	constructor(props) {
		super(props);
		this.state = {
			rules: {},
			deckNotes: "",
			deckName: "",
			basicFont: true
		};
	}

	//Change Font between Basic and K RNH 20210910
	changeFont(fontType) {
		
		this.setState({basicFont: fontType})			
		
		
		let subsyncer = new sync()
		subsyncer.push();
	}

	componentDidMount() {
		if (this.props.params.name) {
			let editName = decodeURIComponent(this.props.params.name);

			localforage.getItem(dataKeys.DECKS)
				.then((decks) => {
					for (let deckIndex = 0; deckIndex < decks.length; deckIndex++) {
						let deck = decks[deckIndex];

						if (deck.normalized_name.toLowerCase() === editName.toLowerCase()) {
							this.setState({ deckName: deck.title, deckNotes: deck.notes, basicFont: deck.basicFont });
							break;
						}
					}
				});
		}
	}

	async SaveDeck() {
		let checkedValues = document.querySelectorAll("#optionTable input[type=checkbox]:checked");
		let deck = {};

		deck.title = this.state.deckName;
		deck.notes = this.state.deckNotes;
		deck.modified = new Date().getTime();
		deck.basicFont = this.state.basicFont;

		if ((!deck.title) || (deck.title.trim() === "")) {
			let { value: tmpTitle } = await Swal.fire({
				title: "Please enter a name for this deck",
				input: "text",
				inputValidator: (value) => {
					if (!value) {
						return "A deck name is required"
					}
				}
			})

			if (!tmpTitle) {
				return;
			}

			deck.title = tmpTitle;
		}

		for (let valueIndex = 0; valueIndex < checkedValues.length; valueIndex++) {
			let input = checkedValues[valueIndex];
			let rule = input.dataset.key;
			let type = input.dataset.type;
			let value = input.value;

			if (!deck[rule]) {
				deck[rule] = {
					"categories": {}
				};
			}

			if (!deck[rule].categories[type]) {
				deck[rule].categories[type] = {
					"values": []
				}
			}

			deck[rule].categories[type].values.push(value);
		}

		localforage.getItem(dataKeys.DECKS)
			.then(async (decks) => {
				let wasDeckFound = false;

				if (!decks || typeof decks.length === 'undefined' || decks.length - decks.filter(fa => fa.deleted === 1 || fa.deleted === -1).length === 0) {
					decks = [];
				}

				for (let deckIndex = 0; deckIndex < decks.length; deckIndex++) {
					if (deck.title.toLowerCase() === decks[deckIndex].title.toLowerCase()&& !decks[deckIndex].lessonDeck) {

						if (decks[deckIndex].deleted !== -1) {
							let { value: overWriteDeck } =

								await Swal.fire({
									title: "Deck Already Exists",
									showCancelButton: true,
									html: `A deck titled ${deck.title} currently exists. Do you want to replace this deck?<br />(If you choose cancel you can change the title and try again)`,
									icon: "warning"
								});

							wasDeckFound = true;

							if (overWriteDeck) {
								deck.modified = (new Date()).getTime();
								deck.normalized_name = decks[deckIndex].normalized_name;
								decks[deckIndex] = deck;
								this.syncer.push();
								break;
							} else {
								return;
							}
						} else {
							wasDeckFound = true;
							deck.modified = (new Date()).getTime();
							deck.normalized_name = decks[deckIndex].normalized_name;
							decks[deckIndex] = deck;
							break;
						}
					}
				}

				if (!wasDeckFound) {
					let index = 1;
					deck.created = (new Date()).getTime();
					deck.normalized_name = NormalizeDeckName(deck.title, index);
					//Check for unique normalized name
					for (let deckIndex = 0; deckIndex < decks.length; deckIndex++) {
						if (decks[deckIndex].normalized_name === deck.normalized_name) {
							index++;
							deck.normalized_name = NormalizeDeckName(deck.title, index);
							while (decks[deckIndex].normalized_name === deck.normalized_name) {
								index++;
								deck.normalized_name = NormalizeDeckName(deck.title, index);
							}
							deckIndex = -1;//We now must look through the decks until we are absolutely sure there is no match
						}
					}

					decks.push(deck);
				}

				localforage.removeItem(dataKeys.DECKS).then(() => {
					localforage.setItem(dataKeys.DECKS, decks).then(() => {

						let html = `${deck.title} has been saved`;
						this.syncer.push()
						Swal.fire({
							title: "Added",
							html: html,
							icon: "success"
						}).then((result) => {

							window.location = `#/SaveLoadDecks/`
						});

					});

				});

			});

	}

	render() {
		let editName;
		
		if (this.props.params.name)
		{editName = this.props.params.name;}
		return (
			<React.Fragment>
				<div id="options-menu" className="p-2">
					<h1>Build Deck</h1>
					<div>
						<button className="form-control mb-2" id="deck-title" onClick={this.SaveDeck.bind(this)}>Complete</button>
					</div>
					{/*
					<div>
						<button className="form-control mb-2" id="deck-font" onClick={this.changeFont.bind(this)} >{this.state.basicFont?"Basic Font":"Kindergarten Font"}</button>
					</div>
					*/}

					<div className="btn-group" data-toggle="buttons">
						<label className={this.state.basicFont ? "btn btn-success" : "btn btn-light"} onClick={()=>{this.changeFont(true)}}>
							<input type="checkbox" name="options" id="option1" autoComplete="off" checked={this.state.basicFont} /*onChange={()=>{}}*/ /> Elementary Font
						</label>
						<label className={!this.state.basicFont ? "btn btn-success" : "btn btn-light"} onClick={()=>{this.changeFont(false)}}>
							<input type="checkbox" name="options" id="option2" autoComplete="off" checked={!this.state.basicFont} /*onChange={()=>{}}*/ /> Kindergarten Font
						</label>

					</div>


					<div className="form-group">
						<input type="text" className="form-control" id="deck-title" value={this.state.deckName} placeholder="Deck Name" onChange={(event) => this.setState({ deckName: event.target.value })} />
					</div>

					<div className="form-group" id="optionTable">
						<RuleSet ruleName={editName} />
					</div>

					<div className="form-group">
						<textarea className="notes form-control" value={this.state.deckNotes} placeholder="Enter notes here..." onChange={(event) => this.setState({ deckNotes: event.target.value })}></textarea>
					</div>

					<div className="form-group">
						<input type="text" className="form-control" id="deck-title" value={this.state.deckName} placeholder="Deck Name" onChange={(event) => this.setState({ deckName: event.target.value })} />
					</div>

					<button className="form-control" onClick={this.SaveDeck.bind(this)}>Complete</button>
				</div>
			</React.Fragment>
		);
	}
}

class RuleSet extends React.Component {
	render() {
		let rules = []

		for (let key in CardDeckRules) {
			rules.push(
				<div key={key} className="section">
					<h1><u>{key}</u></h1>
					<Rule ruleKey={key} ruleName={this.props.ruleName} />
				</div>
			);
		}

		return rules;
	}
}

class Rule extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"customDeck": {}
		};
	}

	componentDidMount() {
		if (this.props.ruleName) {
			let editName = decodeURIComponent(this.props.ruleName);

			localforage.getItem(dataKeys.DECKS)
				.then((decks) => {
					for (let deckIndex = 0; deckIndex < decks.length; deckIndex++) {
						let deck = decks[deckIndex];

						if (deck.normalized_name.toLowerCase() === editName.toLowerCase()) {
							this.setState({ customDeck: deck });
							break;
						}
					}
				});
		}
	}

	render() {
		let ruleSet = [];

		for (let key in CardDeckRules[this.props.ruleKey].categories) {
			let RuleSetValues = [];
			let set = CardDeckRules[this.props.ruleKey].categories[key];

			for (let valueIndex = 0; valueIndex < set.values.length; valueIndex++) {

				let ruleSetValue = set.values[valueIndex];
				let ruleName = "a" + uuidv5(this.props.ruleKey + key + ruleSetValue + valueIndex, uuidv5.URL);
				let checkBox;

				if (this.state.customDeck[this.props.ruleKey]) {
					let deckRuleSet = this.state.customDeck[this.props.ruleKey];

					if (deckRuleSet.categories[key]) {
						if (deckRuleSet.categories[key].values.includes(ruleSetValue)) {
							checkBox = "checked";
						}
					}
				}

				RuleSetValues.push(
					<div key={valueIndex} className="form-check form-check-inline">
						<input type="checkbox" value={ruleSetValue} defaultChecked={checkBox} className="form-check-input" data-key={this.props.ruleKey} data-type={key} id={ruleName} name={ruleName} />
						<label className="form-check-label" htmlFor={ruleName}>{ruleSetValue}</label>
					</div>
				);
			}

			ruleSet.push(
				<div key={key} className="subsection border-bottom ml-4">
					<h3>{key} <button className="btn btn-dark btn-sm ml-2" data-rule={this.props.ruleKey} data-type={key} onClick={this.SelectAllCategories.bind(this)}>Select All</button></h3>
					{RuleSetValues}
				</div>
			)
		}
		return ruleSet;
	}

	SelectAllCategories(evt) {
		let rule = evt.target.dataset.rule;
		let category = evt.target.dataset.type;

		let checkedItems = document.querySelectorAll(`#optionTable input[type=checkbox]:checked[data-type='${category}']`);

		if (checkedItems.length === 0) {
			if (CardDeckRules[rule].categories[category].mark_consonant_category) {
				let mark_category = CardDeckRules[rule].categories[category].mark_consonant_category;
				let consonantCategory = document.querySelectorAll(`#optionTable input[type=checkbox][data-key='Consonants'][data-type='${mark_category}']`);
				for (let itemIndex = 0; itemIndex < consonantCategory.length; itemIndex++) {
					consonantCategory[itemIndex].checked = true;
				}
			}

			if (CardDeckRules[rule].categories[category].mark_syllable_category) {
				let mark_category = CardDeckRules[rule].categories[category].mark_syllable_category;
				let syllableCategory = document.querySelectorAll(`#optionTable input[type=checkbox][data-key='Syllable'][data-type='${mark_category}']`);
				for (let itemIndex = 0; itemIndex < syllableCategory.length; itemIndex++) {
					syllableCategory[itemIndex].checked = true;
				}
			}

			let itemsToCheck = document.querySelectorAll(`#optionTable input[type=checkbox][data-type='${category}']`);
			for (let itemIndex = 0; itemIndex < itemsToCheck.length; itemIndex++) {
				itemsToCheck[itemIndex].checked = true;
			}
		} else {

			if (CardDeckRules[rule].categories[category].mark_consonant_category) {
				let mark_category = CardDeckRules[rule].categories[category].mark_consonant_category;
				let consonantCategory = document.querySelectorAll(`#optionTable input[type=checkbox]:checked[data-key='Consonants'][data-type='${mark_category}']`);
				for (let itemIndex = 0; itemIndex < consonantCategory.length; itemIndex++) {
					consonantCategory[itemIndex].checked = false;
				}
			}

			if (CardDeckRules[rule].categories[category].mark_syllable_category) {
				let mark_category = CardDeckRules[rule].categories[category].mark_syllable_category;
				let syllableCategory = document.querySelectorAll(`#optionTable input[type=checkbox]:checked[data-key='Syllable'][data-type='${mark_category}']`);
				for (let itemIndex = 0; itemIndex < syllableCategory.length; itemIndex++) {
					syllableCategory[itemIndex].checked = false;
				}
			}

			for (let itemIndex = 0; itemIndex < checkedItems.length; itemIndex++) {
				checkedItems[itemIndex].checked = false;
			}
		}
	}
}
