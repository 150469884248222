import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import { createRoot } from 'react-dom/client';

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GAPI_KEY, DISCOVERY_DOCS, GAPI_CLIENT_ID, SCOPES } from "./RooneyMain/SyncSettings/Google";
import { dataKeys } from './RooneyMain/Globals';
import localforage from 'localforage';


window.gapi.load("client:auth2", async function () {

	try {
		await window.gapi.client.init({
			apiKey: GAPI_KEY,
			clientId: GAPI_CLIENT_ID,
			discoveryDocs: DISCOVERY_DOCS,
			scope: SCOPES
		})

		window.gapi.auth2.getAuthInstance().isSignedIn.listen(function (isSignedIn) {
			console.log('user is signed in', isSignedIn)


		});

		window.gapi.auth2.getAuthInstance().currentUser.listen(function (currentUser) {
			console.log('user is signed in ')
		});

		await window.google.accounts.id.initialize({
			client_id: GAPI_CLIENT_ID
		});

		window.tokenClient = window.google.accounts.oauth2.initTokenClient({
			client_id: GAPI_CLIENT_ID,
			scope: SCOPES,
			prompt: "select_account",
			callback: (resp) => { console.log(resp) }, // defined later
		});

		await window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/oauth2/v1/rest");
	} catch (e) {
		console.error(e);
	}

	const container = document.getElementById("app");
	const root = createRoot(container); // createRoot(container!) if you use TypeScript
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
