import "./App.css";
import React from "react";
import { Puff } from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import packageJson from "../package.json";

import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import { PopulateWordsDB, SwabTheDecks } from "./RooneyMain/Globals";
import { NavMenu } from "./RooneyMain/NavMenu";
import { VisualDrill, BlendingDrill } from "./RooneyMain/Drill";
import { HandWritingDrill } from "./RooneyMain/HWDrill";
import { SaveLoadDecks } from "./RooneyMain/SaveLoadDecks";
import { Home } from "./RooneyMain/Home";
import { Diagnostics } from "./RooneyMain/Diagnostics";
import { DeckConfiguration } from "./RooneyMain/DeckConfiguration";
import { CheckLastBackup } from "./RooneyMain/SyncSettings/Preferences";

import { RecycleBin } from "./RooneyMain/RecycleBin";
import withParams from "./hoc";
//import localforage from "localforage";

const semverGreaterThan = (versionA, versionB) => {
	const versionsA = versionA.split(/\./g);

	const versionsB = versionB.split(/\./g);
	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());

		const b = Number(versionsB.shift());

		// eslint-disable-next-line no-continue
		if (a === b) continue;
		// eslint-disable-next-line no-restricted-globals
		return a > b || isNaN(b);
	}
	return false;
};

let version = "1.000.69";

function LogLocationChange() {
	let location = useLocation();

	React.useEffect(() => {

		var pathArray = location.pathname.split('/');
		var newPathname = "/";

		if ((pathArray[pathArray.length - 2] === 'VisualDrill' || pathArray[pathArray.length - 2] === 'BlendingDrill' || pathArray[pathArray.length - 2] === 'DeckConfiguration') && pathArray.length > 2)
			for (let i = 0; i < pathArray.length - 1; i++) {
				newPathname += pathArray[i];
			}

		window.gtag("config", "G-ZVBD5X8RLM", {

			//"page_path": location.pathname
			"page_path": newPathname
		});
	}, [location]);

	return null;
}

export default class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			HasLoadedWords: false,
			HasLoadedLessonDecks: false,
			HasCleanedDecks: false,
			HasSyncedDecks: true, //Need to reset to false after sync programing,
			busyCount: 0,
		};
	}

	componentDidMount() {
		PopulateWordsDB(true, this.wordsLoaded.bind(this));

		let lplan = new SaveLoadDecks()
		lplan.loadLessonPlans().then(() => {
			this.setState((state) => ({ HasLoadedLessonDecks: true }));
		})


		CheckLastBackup();




		//possible delete
		if (semverGreaterThan(packageJson.version, version)) {
			//window.location.reload();
		}


	}

	wordsLoaded() {
		this.setState((state) => ({ HasLoadedWords: true }));

		SwabTheDecks(this.decksCleaned.bind(this));
	}

	decksCleaned() {
		this.setState((state) => ({ HasCleanedDecks: true }));
	}

	decksSynced() {
		this.setState((state) => ({ HasSyncedDecks: true }));
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (nextState.HasLoadedWords) && (nextState.HasCleanedDecks);
	}

	setBusyState(isBusy) {
		this.setState((state) => {
			if (isBusy) {
				return { busyCount: (state.busyCount + 1) }
			}

			if (state.busyCount === 0) {
				return { busyCount: 0 }
			}

			return { busyCount: (state.busyCount - 1) }
		});
	}

	render() {
		let showProgress = ((this.state.HasLoadedWords) && (this.state.HasCleanedDecks) && (this.state.HasSyncedDecks) && (this.state.HasLoadedLessonDecks));
		let loader;
		let busyIndicator;

		if (!showProgress) {
			loader = (
				<div id="progress-loader">
					<div className="loader mx-auto">
						<Puff color="#00BFFF" height={100} width={100} />
					</div>
				</div>
			);
			return loader
		}

		if (this.state.busyCount > 0) {
			busyIndicator = (
				<div id="progress-loader">
					<Puff className="loader mx-auto" color="#00BFFF" height={100} width={100} />
				</div>
			);
		}


		return (
			<Router>
				<LogLocationChange />
				{loader}
				{busyIndicator}
				<div className="main d-flex">
					<NavMenu />
					<div className="flex-fill">
						<Routes>
							<Route path="/SaveLoadDecks/:name" Component={SaveLoadDecks} />
							<Route path="/SaveLoadDecks" Component={SaveLoadDecks} />
							<Route path="/DeckConfiguration/:name"  Component={withParams(DeckConfiguration)} />
							<Route path="/DeckConfiguration" name="DeckConfiguration" Component={withParams(DeckConfiguration)} />
							<Route path="/VisualDrill/:deck" Component={withParams(VisualDrill)} />
							<Route path="/BlendingDrill/:deck" Component={withParams(BlendingDrill)} />
							<Route path="/HandWritingDrill" Component={HandWritingDrill} />
							<Route path="/Diagnostics" Component={Diagnostics} />
							<Route path="/Diagnostics" Component={Diagnostics} />
							<Route path="/code=:token" Component={Home} />
							<Route path="/RecycleBin" Component={RecycleBin} />
							<Route path="/" element={<Home isBusy={this.setBusyState.bind(this)} />} />
						</Routes>
						<div className="version row">
							<div className="col-12"><span className="text-muted">{version}</span></div>
						</div>
						<div className="copyright row">
							<div className="col-12"><span className="text-muted">&copy; 2020 M A Rooney Foundation. All rights reserved.</span></div>
						</div>
						<div className="developer row">
							<div className="col-12"><span className="text-muted text-small">Developed by Jonathan Nichols for M A Rooney Foundation</span></div>
						</div>
					</div>
				</div>
			</Router>
		);
	}
}