import Swal from "sweetalert2";
import localforage from "localforage";

import { dataKeys } from "../Globals";
import { FileNameBackup, runSync, DeleteBackupProvider, removeSyncVariables } from "./Preferences";
import sync from "./Sync";
import { ClassEvents, IDictionary, SyncProvider } from "./Interfaces";
import { useCallback } from "react";
import { formatDiagnosticsWithColorAndContext, isNonNullExpression } from "typescript";
import userEvent from "@testing-library/user-event";

export const GAPI_KEY = "AIzaSyAHowu5ei8tw_ll-d9pXy9YDBTlWTwPrpU";
export const GAPI_CLIENT_ID = "36289810813-7qipf4khr68gkjm6f14ea0g91sgcmnns.apps.googleusercontent.com";
export const SCOPES = "email https://www.googleapis.com/auth/drive.file";
export const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

type GoogleFiles = {
	id: string;
	name: string;
	content: string;
	modified: Date;
	link: string;
}

export default class Google implements SyncProvider, ClassEvents {
	SCOPES: string = "email https://www.googleapis.com/auth/drive.file";
	listeners: IDictionary = {};

	constructor() {
		let self = this;
		localforage.getItem<string>("backupDateTime").then((backupDate: string | null) => { self.backupDateTime = backupDate === null ? 'Not Set' : backupDate });
	};

	public name: string = "Google";
	public instructions: string = "Google";
	public subtext: string = "We are now going to authenticate the M.A. Rooney Foundation to upload files to your Google Drive. Further instructions & details will be provided on the next screen. You will be temporarily directed to Google and then returned to the homepage of this app.";

	public backupDateTime: string = 'Not Set';
	public async completeSyncKey(): Promise<boolean> {
		return true
	}

	public addEventListener(type: string, listener: Function): void {
		this.listeners[type] = listener;
	}

	public removeEventListener(type: string): void {
		delete this.listeners[type];
	}

	public dispatchEvent(type: string, payload: any): void {
		if (this.listeners[type]) {
			this.listeners[type](payload);
		}
	}

	public async auth(): Promise<void> {


		window.tokenClient.callback = async (resp: any) => {
			/*
			tokenClient:
			{
				"access_token": "ya29.a0AeTM1ifzUf5JoY_Sp42XqAPQcHjk7IF90SbrT9c1ceTvuXv-XELy-7x6HD977hTJpUmnZz4gEkadCFpGLPczBovW9SnpZfDaYOyiQ4m15GtlG-kRl4UcwFcj3Z9PL47-GlkuL9Lgt3SwcJ1KXTt5UEXHK2LafgaCgYKAUUSARESFQHWtWOm8XRSxgymHwNnbDNjr97gwg0165",
				"token_type": "Bearer",
				"expires_in": 3599,
				"scope": "email profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
				"authuser": "0",
				"prompt": "none"
			}
			*/

			this.dispatchEvent('backupAuthComplete', {})
			if (resp.error !== undefined) {
				throw (resp);
			}

			await localforage.setItem<string>(dataKeys.BACKUPAUTHTOKEN, resp.access_token);
			await localforage.setItem<number>(dataKeys.REFRESHTIME, resp.expires_in);
			await localforage.setItem<number>(dataKeys.LASTREFRESHTIME, new Date().getTime());
			await localforage.setItem<string>(dataKeys.USEREMAIL, await this.profileTest());


			let syncer = new sync();

			await syncer.pull().then(syncer.push.bind(syncer));

			window.location.reload();


		}

		if (await window.gapi.client.getToken() === null) {
			window.tokenClient.requestAccessToken({
				prompt: "consent",
				scope: this.SCOPES
			});
		} else {
			await window.tokenClient.requestAccessToken({
				prompt: "",
				scope: this.SCOPES
			});
		}
	}

	public async setup(): Promise<void> { }

	public async backup(): Promise<void> {
		let self = this;

		if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
			await this.uploadToGoogle();
		} else {
			window.gapi.auth2.getAuthInstance().isSignedIn.listen(async function (isSignedIn: boolean) {
				if (isSignedIn) {
					await self.uploadToGoogle();
				} else {
					Swal.fire({
						title: "Google Auth Error",
						text: "There was an error authenticating with Google",
						icon: "error"
					});
				}
			});

			this.auth();
		}
	}

	public async sync(): Promise<void> {


		await this.isUserLoggedIn();

		let fileListRequest = await this.getBackupFiles();


		if (fileListRequest.length === 0) { return };

		var request = await window.gapi.client.drive.files.get({
			fileId: fileListRequest[0].id,
			alt: 'media'
		});

		let data = JSON.parse(request.body);
		await localforage.setItem(dataKeys.DBCLOUDDATA, data);
	}

	public async synccheck(): Promise<boolean> {
		console.log('test synccheck')

		await this.isUserLoggedIn();

		let fileListRequest = await this.getBackupFiles();

		if (fileListRequest.length === 0) { return false; };

		let googleFileName = fileListRequest[0].name;
		let googleModDate = new Date(fileListRequest[0].modified);

		let localModDate = await localforage.getItem<number>(dataKeys.LASTFILEBACKUP) ?? 0;
		let index = await localforage.getItem(dataKeys.BACKUPINDEX);
		//if (index === 1) { index = 7 } else { index = index - 1 }

		let localModName = 'DeckBackup' + index + '.json'
		if (googleModDate.getTime() - localModDate === 0 && localModName === googleFileName) { return true; }

		if (/*googleModDate.getTime() - localModDate === 0 &&*/ localModName === googleFileName) {
			return true;
		}

		if (fileListRequest.length !== 0) {
			if (navigator.onLine) {
				//setTimeout(this.synccheck.bind(this), 3000);
			}
		}

		return false;
	}

	public async fileName(): Promise<string> {
		return this.getBackupFileName();
	}

	public async token(code: string): Promise<void> { }

	//Private functions

	async isUserLoggedIn(): Promise<void> {
		console.log("isUserLoggedIn");
		if (!window.gapi.auth2.getAuthInstance().isSignedIn.get()) {

		}
	}

	async uploadToGoogle(): Promise<void> {
		let decks = await localforage.getItem(dataKeys.DECKS);

		if (!decks) { return; }

		if (!navigator.onLine) {
			Swal.fire({
				title: "No Network",
				text: "Please check your network connection and try again.",
				icon: "warning"
			});

			return;
		}

		//let token = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN);

		//let currentDate = moment(); RNH Replaced this varialbe 1 to 7 Backup
		let downloadText = JSON.stringify(decks, undefined, "\t");
		let blob = new Blob([downloadText], { type: "application/json;charset=utf-8" });

		//let filename = await FileNameBackup();
		console.log('test upload to google')
		let backups = await this.getBackupFiles();

		let shouldUpdate: boolean = false;

		if (backups.length > 0 && backups.length === 7) {
			shouldUpdate = true;
		}

		if (shouldUpdate) {
			let patchRequest: any;

			let backupId = backups[0].id;

			if (backups.length === 7) {
				backupId = backups[6].id;
			}

			try {
				patchRequest = await window.gapi.client.request({
					path: `/upload/drive/v3/files/${backupId}`,
					method: "PATCH",
					params: {
						uploadType: "media"
					},
					body: downloadText
				});
			} catch (e) {

				Swal.fire({
					title: "Sync Failed",
					html: `<p>A Sync could not be created. You may want to check your internet connection and run a manual backup.</p><pre>(${e})</pre>`,
					icon: "error"
				});

				return;
			}

			await localforage.setItem(dataKeys.LASTFILEBACKUP, new Date().getTime());

			if (patchRequest.status === 200) {
				this.dispatchEvent("backup", patchRequest)
				return;
			}
		} else {

			let filename = await this.getBackupFileName(backups);

			let form = new FormData();
			form.append("metadata", new Blob([JSON.stringify({
				"name": filename,
				"mimeType": "application/json;charset=utf-8"
			})], { type: "application/json" }));

			form.append("file", blob);

			let response = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id", {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${window.gapi.auth.getToken().access_token}`
				},
				body: form
			});

			if (response.ok) {
				await localforage.setItem(dataKeys.LASTFILEBACKUP, (new Date()).getTime())
				this.dispatchEvent("backup", response)
				return;
			}

			let body = await response.text();

			Swal.fire({
				title: "Sync Failed",
				html: `<p>A sync file could not be created. You may want to check your internet connection and run a manual backup.</p><pre>(${body})</pre>`,
				icon: "error"
			});
		}
	}

	async getBackupFileName(backups?: GoogleFiles[]): Promise<string> {

		console.log('getbackupfilename')


		if (!backups) {
			backups = await this.getBackupFiles();
		}

		let backupIndex = 1;
		if (backups.length > 0) {
			backupIndex = parseInt(backups[0].name.substring("DeckBackup".length, "DeckBackup".length + 1), 10);
		}

		if (backupIndex === 7) {
			backupIndex = 0; //We will always increment it by 1 below
		}

		backupIndex++;

		return `DeckBackup${backupIndex}.json`;

	}

	async getBackupFiles(): Promise<GoogleFiles[]> {
		console.log("getBackupFiles")
		let fileListRequest;
		try {
			fileListRequest = await window.gapi.client.drive.files.list({
				q: "mimeType='application/json' and name contains 'DeckBackup' and trashed=false",
				fields: "nextPageToken, files(id, name, modifiedTime, webContentLink)",
				orderBy: "modifiedTime desc"
			});

		} catch (e) {
			await this.checkListRequest(e)
			return [];
		}


		if (fileListRequest.result.files.length === 0) {
			return [];
		}

		let values: GoogleFiles[] = [];

		for (let fileIndex = 0; fileIndex < fileListRequest.result.files.length; fileIndex++) {
			values.push({
				id: fileListRequest.result.files[fileIndex].id,
				name: fileListRequest.result.files[fileIndex].name,
				content: "",
				modified: new Date(fileListRequest.result.files[fileIndex].modifiedTime),
				link: fileListRequest.result.files[fileIndex].webContentLink,
			})
		}

		return values;

	}

	async RefreshAccessToken(): Promise<void> {


		let accessToken = await localforage.getItem<string>(dataKeys.BACKUPAUTHTOKEN);
		let refreshExpires = await localforage.getItem<number>(dataKeys.REFRESHTIME);
		let lastRefresh = await localforage.getItem<number>(dataKeys.LASTREFRESHTIME);
		let userEmail = await localforage.getItem<string>(dataKeys.USEREMAIL);

		console.log("refreshExpires", refreshExpires, "lastRefresh", lastRefresh)

		if (refreshExpires === null || lastRefresh === null || accessToken === null || userEmail === null) {
			return;
		}

		let refreshDate = lastRefresh + (refreshExpires * 1000);

		if (refreshDate < new Date().getTime()) {

			let newToken = window.tokenClient.requestAccessToken({
				prompt: "none",
				scope: this.SCOPES,
				hint: userEmail
			});

			await localforage.setItem(dataKeys.BACKUPAUTHTOKEN, newToken);

			/*let auth = new DropboxAuth({ clientId: "2ar4lg23id993vu" });

			auth.setRefreshToken(refreshToken);

			let result = await auth.refreshAccessToken();

			console.log(result);

			await localforage.setItem(dataKeys.LASTREFRESHTIME, new Date().getTime());*/
		}
	}

	async revokeAccess() {
		//window.google.accounts.oauth2.revoke(localforage.getItem(dataKeys.BACKUPAUTHTOKEN))

	}


	async checkListRequest(resp: any): Promise<void> {
		
		console.log("error ", resp.status)
		console.log(await localforage.getItem<number>(dataKeys.REFRESHTIME))
		if (resp.status && resp.status > 399 && await localforage.getItem<string>(dataKeys.INITIALGMAIL) !== null) //HTTP errors start at 400
		{
			for (let errorIndex = 0; errorIndex < resp.result.error.errors.length; errorIndex++) {
				let error = resp.result.error.errors[errorIndex];

				switch (error.reason) {
					case "insufficientFilePermissions":
					case "appNotAuthorizedToFile":
					case "authError":

						errorIndex = resp.result.error.errors.length + 1

						let value = await Swal.fire({
							"title": "Attention",
							"text": "Google is your sync provider and is requiring you to re-authenticate your account.",
							showCancelButton: true,
							allowOutsideClick: false,
							confirmButtonText: 'Re-Authenticate',
							cancelButtonText: 'Remove Google as sync provider',
						});
						if (value.isConfirmed === true) {
							await this.auth()
							break;

						} else {
							DeleteBackupProvider()
						}

						break;
					default:
						break;
				}
			}
		}
	}
	async profileTest() {
		console.log("ProfileTest")
		if (await localforage.getItem(dataKeys.INITIALGMAIL) === null) {
			await window.gapi.auth2.getAuthInstance().signIn()
			let email = await window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()
			localforage.setItem(dataKeys.INITIALGMAIL, email);
			return email;
		}

		try {
			window.gapi.auth2.getAuthInstance().isSignedIn.get()
			let email = await window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail()

			return email;

		} catch (e) {
			console.log("Profile Error")


			await Swal.fire({
				title: "Invalid Sync Request",
				html: '<a href = "mailto: ogdrill_support@marooneyfoundation.org">Contact Support</a>',
				icon: "error"
			});

			await removeSyncVariables()
			return "error"
				;



		}

	}
}