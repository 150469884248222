import "../App.css"
import React from "react";
import HWAnimationBoard from "./HWAnimationBoard";

export class HandWritingDrill extends React.Component {
    render() {
        return <HWDrill type="handwriting" />;
    }
}

class HWDrill extends React.Component {
    constructor(props) {
        super(props);

        this.words = [];

        this.state = {
            uppers: Array(26).fill().map((letter, index) => String.fromCharCode('A'.charCodeAt(0) + index)),
            lowers: Array(26).fill().map((letter, index) => String.fromCharCode('a'.charCodeAt(0) + index)),
            linesPic: 'assets/cards/HWLines.png',
            htmlTable: null,
            letter: "La"
        };
    }

    componentDidMount() {

    }

    getCells(data) {
        return data.map(cell => <td className='handwritingtable td' key={data.indexOf(cell)} onClick={() => { this.cardSwap(cell) }}>{cell}</td>);
    }

    createBody(data) {
        return data.map(row => <tr className='handwritingtable tr' key={'r' + data.indexOf(row)}>{this.getCells(row)}</tr>);
    }

    createTable(data) {

        // Destructure the headings (first row) from
        // all the rows
        const [headings, ...rows] = data;

        // Return some HTML that uses `getCells` to create
        // some headings, but also to create the rows
        // in the tbody.
        return (

            <table className="handwritingtable">
                {/*<th>{this.createBody(headings)}</th>*/}
                <tbody className='handwritingtable tbody'>{this.createBody(rows)}</tbody>
            </table>)
    }

    cardSwap(xCell) {

        //document.getElementById('mainImage').src = "assets/cards/hw" + (xCell.toUpperCase() === xCell ? "u" : "l") + xCell + ".png";
        this.setState(
            {
                letter: (xCell.toUpperCase() === xCell ? "U" : "L") + xCell
            }
        )

    }

    RenderHandWriting() {

        return (

            <React.Fragment>

                <div className='handwriting mt-3'>

                    {this.createTable([[], this.state.uppers, this.state.lowers])}

                </div>
                <div className="deck-cards mt-3">
                    <div className="row  mb-5">
                        <div className="col">
                            <img id='mainImage' className='border rounded img-fluid d-block m-auto' style={{ "width": "20.3vw" }} src={`assets/cards/hw${this.state.letter}.svg`} alt='' />
                        </div>
                    </div>

                    <div className="row">
                        <HWAnimationBoard Letter={this.state.letter} />
                    </div>
                </div>

            </React.Fragment>
        );
    }

    render() {

        let render = this.RenderHandWriting();

        return render;
    };
};