const CardDeckRules = {
	"Syllable": {
		"categories": {
			"Closed": {
				"values": ["a", "e", "i", "o", "u"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Open": {
				"values": ["a", "e", "i", "o", "u", "y"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Magic e": {
				"values": ["a", "e", "i", "o", "u", "y"],
				"mark_consonant_category": "Magic E",
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"R Controlled": {
				"values": ["ar", "er", "ir", "or", "ur"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Short Vowel Exceptions": {
				"values": ["ang", "ank", "ild", "ind", "ing", "ink", "old", "oll", "olt", "ong", "onk", "ost", "ung", "unk"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Vowel Team Basic": {
				"syllable_type": "Vowel Team",
				"values": ["ai", "ay", "ea", "ee", "ie=i", "igh", "oa", "oe", "ue", "oy"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Vowel Team Intermediate": {
				"syllable_type": "Vowel Team",
				"values": ["aw", "eigh", "ew", "ey", "ie=e", "oi", "oo", "ou", "ow"],
				"value_rewrite": {
					"ie=e": "ie"
				},
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Vowel Team Advanced": {
				"syllable_type": "Vowel Team",
				"values": ["au", "ei", "eu", "ui"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Vowel a": {
				"values": ["al", "all", "am", "an", "qua", "wa"],
				"field": [{
					"card_position": 2,
					"field": "face"
				}],
				"show_in_visual": true
			}
		}
	},
	"Consonants": {
		"categories": {
			"Single Consonants": {
				"values": ["b", "c", "d", "f", "g", "h", "j", "k", "l", "m", "n", "p", "qu", "r", "s", "t", "v", "w", "x", "y", "z"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}, {
					"card_position": 3,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"H Brothers": {
				"values": ["ch", "ph", "sh", "th", "wh"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}, {
					"card_position": 3,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Beginning Blends": {
				"values": ["bl", "br", "cl", "cr", "dr", "fl", "fr", "gl", "gr", "pl", "pr", "sc", "scr", "shr", "sk", "sl", "sm", "sn", "sp", "spl", "spr", "squ", "st", "str", "sw", "thr", "tr", "tw"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Short Vowel Pointers": {
				"values": ["ck", "dge", "tch", "ff", "ll", "ss", "zz"],
				"field": [{
					"card_position": 3,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Ending Blends": {
				"values": ["sk", "sp", "st", "ct", "ft", "ld", "lk", "lp", "lt", "mp", "nch", "nd", "nt", "pt"],
				"field": [{
					"card_position": 3,
					"field": "face"
				}],
				"show_in_visual": true
			},
			"Magic E": {
				"values": ["be", "ce", "de", "fe", "ge", "ke", "le", "me", "ne", "pe", "se", "te"],
				"field": [{
					"card_position": 3,
					"field": "face"
				}],
				"mark_syllable_category": "Magic e",
				"show_in_visual": false
			},
			"Soft C & G (E, I, Y)": {
				"values": ["soft c", "soft g"],
				"check_value_against_special_rule": true,
				"field": [{
					"card_position": 1,
					"field": "type"
				},
				{
					"card_position": 3,
					"field": "type"
				}],
				"show_in_visual": true
			},
			"se": {
				"values": ["se"],
				"field": [{
					"card_position": 3,
					"field": "type"
				}],
				"show_in_visual": false
			}
		}
	},
	"Visual": {
		"categories": {
			"Primary Picture Deck": {
				"values": ["a apple", "b boy", "c cat", "d dog", "e edge", "f fish", "g goat", "h hat", "i itch", "j jam", "k kite", "l light", "m man", "n nest", "o ox", "p pig", "qu queen", "r rat", "s snake", "t top", "u up", "v van", "w wagon", "x box", "y yoyo", "z zebra", "ch chair", "wh whistle", "sh ship", "th thumb", "-ck duck", "-ll hill", "-ss class", "-ff off", "-dge bridge", "-tch catch", "a_e cake", "i_e bike", "o_e home", "u_e mule", "u_e tune", "s nose", "all ball"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Graphic Cards": {
				"values": ["a w/ graphic", "e w/ graphic", "i w/ graphic", "o w/ graphic", "u w/ graphic", "r w/ graphic"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Magic e Cards": {
				"values": ["Magic e", "a_e", "e_e", "i_e", "o_e", "u_e", "y_e"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Short Vowel Exceptions - Visual Tools": {
				"values": ["ng", "nk", "other sve"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Syllable Division": {
				"values": ["Kite", "Compound words", "prefix] root [suffix", "consonant +le", "vccv", "vcccv, vccccv", "vcv", "vv", "crazy i"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"c+le(consonant+le)": {
				"values": ["-ble", "-cle", "-c.kle", "-dle", "-fle", "-gle", "-kle", "-ple", "-s.tle", "-tle", "-zle", "Consonant+ le"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Tools": {
				"values": ["Vowel Card","schwa Ə", "ch", "sh", "th", "wh", "ph", "-ank", "-ink", "-onk", "-unk", "-ang", "-ing", "-ong", "-ung", "-ind", "-old", "-ild", "-ost", "-olt", "-oll", "b, d with graphics", "b-blue", "b", "d-blue", "d", "baby", "sad", "bat", "dip", "aEIouY", "/k/ kite/cat graphic", "c/s/EIY", "soft sound of c", "g/j/EIY", "soft sound of g", "-ck", "-dge", "-tch", "floss", "syllable", "clover", "closed", "Consonant+le", "open", "vowel team", "e symbol", "Magic e", "R controlled", "I’m the boss", "bossy r", "adding suffixes", "doubling", "e drop", "y rule just add", "y rule suffix i", "y rule plurals", "y rule change the y to i", "Y rule all", "Not with graphic", "CUPS", "slow cow", "piece of pie", "school book", "eat bread steak", "coin", "ouch", "snow plow", "ceiling reindeer", "key they", "August", "tissue/blue", "boat", "eight", "feet", "fruit", "feud/sleuth", "few/grew", "light", "play", "rain", "saw", "toe", "toy"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Prefixes & Suffixes Tools": {
				"values": ["prefix", "suffix", "vowel suffix consonant suffix"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Prefixes": {
				"values": ["a-", "ab-", "ac-", "ad-", "ambi-", "amphi-", "ante-", "anti-", "ap-", "arch-", "archi-", "astro-", "auto-", "bi-", "bio-", "centi-", "chrono-", "circum-", "co-", "col-", "com-", "con-", "contra-", "cor-", "counter-", "de-", "deca-", "deci-", "demi-", "di-", "dia-", "dis-", "dys-", "ec-", "epi-", "equi-", "ex-", "extra-", "for-", "geo-", "hemi-", "hetero-", "hexa-", "homo-", "hydro-", "hyper-", "hypo-", "il-", "im-", "in-", "inter-", "intra-", 
						  "intro-", "ir-", "kilo-", "macro-", "mega-", "meta-", "micro-", "mid-", "mille-", "milli-", "mini-", "mis-", "mon-", "mono-", "multi-", "non-", "ob-", "octo-", "omni-", "ortho-", "osteo-", "out-", "over-", "paleo-", "pan-", "para-", "patho-", "penta-", "per-", "peri-", "phono-", "photo-", "physio-", "phyto-", "poly-", "post-", "pre-", "pro-", "pseudo-", "psycho-", "pyro-", "re-", "retro-", "self-", "semi-", "sept-", "sub-", "suc-", "suf-", "sug-", "sum-", "sup-", "sur-", "sus-", "sym-", "syn-", "tele-", "tetra-", "thermo-", "trans-", "tri-", "ultra-", "un-", "uni-"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Suffixes": {
				"values": ["-able", "-ac", "-ad", "-age", "-al", "-ar", "-ard", "-ation", "-cial", "-cian", "-cide", "-cious", "-dom", "-ed", "-ee", "-eer", "-en", "-er", "-es", "-ess", "-est", "-ette", "-ful", "-ia", "-ial", "-ian", "-ible", "-ic", "-ice", "-ine", "-ing", "-ish", "-ist", "-ite", "-ive", "-less", "-ly", "-ment", "-ness", "-or", "-ous", "-s", "-sion", "-tial", "-tian", "-tion", "-ture", "-y"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Other Patterns": {
				"values": ["-alk","gh", "gn", "gu", "kn", "-mb", "-mn", "rh-", "war", "wor", "wr"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			},
			"Roots": {
				"values": ["aqua", "aud", "cap capt", "capit", "cede", "ceit ceive cept", "claim", "cred", "cur", "dic dict", "duc duce duct", "fac fact fect fic", "fer", "flect flex", "fluc flux", "form", "gen(e)", "grad gress", "gram graph", "ject", "join(t) junct", "jud jur jus(t)", "lect", "leg", "mis mit", "mob mot mov", "mor", "nat", "nav", "nov", "pend", "pens", "port", "rect", "reg", "rupt", "scrib script", "sec sect", "spec spect", "spir spire", "sist stat stit", "stru struct", "tact tag tang", "tain ten tin", "tend tens", "tent", "tract", "vent", "vers vert"],
				"field": [{
					"card_position": 1,
					"field": "face"
				}]
			}
		}
	}
};




export default CardDeckRules;

